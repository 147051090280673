@keyframes btn_generalBgr {

  0%{
    background-position : 0%;
    box-shadow: 0 0 .8em #7A3E07;
  }
  50%{
    background-position: 60%;
    box-shadow: 0 0 .8em #F3C343;
  }
  100%{
    background-position: 100%;
    box-shadow: 0 0 .8em #C1872B;
  }

}

@keyframes btn_generalBgrLight{

  0%{
   color: rgb(224, 204, 165);
   box-shadow: rgba(253, 124, 4, 0.5) 0px 54px 55px;
  }
  20%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(224, 111, 5, 0.5) 0px 54px 55px;
  }
  40%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(253, 115, 2, 0.5) 0px 54px 55px;
  }
  60%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(248, 122, 19, 0.5) 0px 54px 55px;
  }
  80%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(241, 91, 4, 0.5) 0px 54px 55px;
  }
  100%{
    color: rgb(204, 197, 184);
    box-shadow: rgba(243, 147, 4, 0.5) 0px 54px 55px;
  }

}