.AllButtons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.btn_single, .btn_multiplayer, .btn_tournaments, .btn_back {
  position: relative;
  margin: .3rem;
  pointer-events: all;
}

.btn_general{
  border-radius : 3rem;
  padding: 1.2rem 1.3rem;
  background-image: var(--general-button-bgr);
  background-size: 400%;
  cursor: pointer;
}

.btn_general:hover{
  animation: btn_generalBgr 3.6s alternate ease-out infinite;;
}

.btn_general > a{
  letter-spacing: .1rem;
  padding: .7rem 2.2rem;
  background: var(--black);
  font: var(--general-button-fonts);
  text-transform: Uppercase;
  border-radius : 1.5rem;
  text-align: center;
  text-decoration: none;
  animation: btn_generalBgrLight 7s alternate infinite;
}