/* Sign in Form */
.signin{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  min-width: 300px;
  height: 392px;
  min-height: 368px;
  background: var(--grey);  
  opacity: 1;
  padding: 40px;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: relative;
  z-index: 5;
  animation: show .4s forwards, signInEvent 1.6s forwards, signInShow 1.1s forwards;
}

.signin_finished{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  min-width: 300px;
  height: 280px;
  min-height: 280px;
  position: relative;
  z-index: 5;
}

.signin_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 40px;
  position: relative;
}
/* Sign in Form */