/* ------ All Players Ready Corner --------- */
.PlayOrWatchArea{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  animation: show .2s forwards;
}

.PlayOrWatchArea-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  position: absolute;
  animation: hide 1.2s forwards;
}

.PlayOrWatchArea-198 {
  display: grid;
  width: 330px;
  height: 50px;
  grid-template-areas: 'overlap';
  opacity: .198;
  place-content: center;
  text-transform: uppercase;
  cursor: default;
  position: absolute;
  top: 4px;
}

.PlayOrWatchArea-198 > div {
  background-clip: text;  
  color: var(--black);
  font: bolder 2.6rem 'Raleway', sans-serif;
  grid-area: overlap;
  letter-spacing: 1px;
  -webkit-text-stroke: 4px transparent;
}

.PlayOrWatchArea-198-bg {
  background-image: repeating-linear-gradient( 105deg, 
    var(--main-color-second) 0% , 
    var(--brown-first) 5%,
    var(--main-color-second) 12%);
  color: transparent;
  filter: drop-shadow(5px 15px 15px black);
  transform: scaleY(1.05);
  transform-origin: top;
}

.PlayOrWatchArea-198-fg{
  background-image: repeating-linear-gradient( 5deg,  
    var(--main-color-second) 0% , 
    var(--brown-first) 23%, 
    var(--main-color-second) 31%);
  color: #1a459b;
  transform: scale(1);
}

.PlayOrWatchArea-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100% / 1.4);
  background: var(--grey-simple);
  border-radius: 1.2rem;
  box-shadow: var(--box-shadow-signin);
  filter: hue-rotate(10deg);
  color: var(--main-wave);
  gap: 20px;
  opacity: 0;
  position: relative;
  animation: show 1.4s forwards, PlayOrWatchArea-198-Bgr 3s alternate ease-out infinite;
}

@media (max-width: 480px) {

  .PlayOrWatchArea-container {
    width: calc(100% / 2);
    min-width: 300px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .PlayOrWatchArea-container {
    width: calc(100% / 2);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .PlayOrWatchArea-container {
    width: calc(100% / 2.35);
    max-width: 340px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .PlayOrWatchArea-container {
    width: calc(100% / 2.35);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .PlayOrWatchArea-container {
    width: calc(100% / 2.35);
  }
}

@media (min-width: 1600px) {

  .PlayOrWatchArea-container {
    width: calc(100% / 2.35);
  }
}

.PlayOrWatchArea-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 1.6);
  height: 44px;
  margin-top: 24px;
  margin-bottom: 14px;
  border: 2px solid;
  border-radius: 5px;
  background: var(--forth-nav);
  box-shadow: var(--box-shadow-signin);
  opacity: .7;
  cursor: default;
}

.PlayOrWatchArea-data h2{
  color: var(--main-wave);
  font: bolder .94rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-simple);
}

/* in room buttons , play or watch */
.PlayOrWatchArea-btn-place{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 58px);
  position: absolute;
  bottom: 8px;
  z-index: 2;
 }

.PlayOrWatchArea-btn-place_button {
  background: var(--black);
  color: var(--white-second);
  width: calc(100% / 2.5);
  height: 34px;
  border: 2px solid transparent;
  border-radius: .3rem;
  margin: .3rem 0;
  box-shadow: var(--box-shadow-features);
  transition: all 0.6s ease;
  cursor: pointer;
  position: relative;
}

@media (max-width: 767px) {

  .PlayOrWatchArea-btn-place_button{
    text-align: center;
  }
}

@media (min-width: 768px) {

  .PlayOrWatchArea-btn-place_button{
    text-align: right;
  }
}

.PlayOrWatchArea-btn-place_button:hover {
  background: var(--forth-nav);
}

.PlayOrWatchArea-btn-place_button svg {
  width: 18px;
  color: #E77000;
  margin: -2px 12px 16px;
  pointer-events: none;
  position: absolute;
  transition: all 0.6s ease;
}

@media (max-width: 767px) {

  .PlayOrWatchArea-btn-place_button svg{
    display: none;
  }
}

@media (min-width: 768px) {

  .PlayOrWatchArea-btn-place_button svg{
    display: flex;
  }
}

.PlayOrWatchArea-btn-place_button:hover svg {
  transform: translateX(15px);
}

.PlayOrWatchArea-btn-place_button_text {
  pointer-events: none;
  margin: 0 16px
}
/* in room buttons , play or watch */

/* in room buttons , settings and exit */
.PlayOrWatchArea-btn-settingsAndExit{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  height: 106px;
  position: absolute;
  bottom: 28px;
  right: 4px;
}

.PlayOrWatchArea-btn-settings{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgba(2, 42, 68, 0.7);
  position: absolute;
  bottom: 60px;
  right: 0;
}

.PlayOrWatchArea-btn-exit{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgba(21, 0, 0, .7);
  position: absolute;
  bottom: 0;
  right: 0;
}

        /* plus sign */
.PlayOrWatchArea-btn-exit-sign, .PlayOrWatchArea-btn-settings-sign{
  width: 100%;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.PlayOrWatchArea-btn-exit-sign svg, .PlayOrWatchArea-btn-settings-sign svg {
  width: 18px;
}

.PlayOrWatchArea-btn-exit-sign svg path, .PlayOrWatchArea-btn-settings-sign svg path {
  fill: white;
}
        /* text */
        
.PlayOrWatchArea-btn-exit-text, .PlayOrWatchArea-btn-settings-text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: .8rem;
  font-weight: 600;
  transition-duration: .3s;
  pointer-events: none;
}

@media (min-width: 992px) {
  /* hover effect on button width */
  .PlayOrWatchArea-btn-exit:hover, .PlayOrWatchArea-btn-settings:hover {
    width: 125px;
    border-radius: 40px;
    transition-duration: .3s;
  }

  .PlayOrWatchArea-btn-exit:hover .PlayOrWatchArea-btn-exit-sign,  .PlayOrWatchArea-btn-settings:hover .PlayOrWatchArea-btn-settings-sign{
    width: 30%;
    transition-duration: .3s;
    padding-left: 20px;
  }

  /* hover effect button's text */
  .PlayOrWatchArea-btn-exit:hover .PlayOrWatchArea-btn-exit-text, .PlayOrWatchArea-btn-settings:hover .PlayOrWatchArea-btn-settings-text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
  }
}

/* button click effect*/
.PlayOrWatchArea-btn-exit:active, .PlayOrWatchArea-btn-settings:active {
  transform: translate(2px ,2px);
}

/* in room buttons , exit */

/* ------ All Players Ready Corner --------- */