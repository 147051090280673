.TournamentsAreaContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background: var(--grey-light);
  box-shadow: var(--box-shadow-features);
  opacity: 0;
  position: relative;
  animation: show .2s forwards;
  z-index: 3;
}

@media (max-width: 480px) {
  
  .TournamentsAreaContainer{
    min-width: 350px;
    width: 100vw;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .TournamentsAreaContainer{
    width: 100vw;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .TournamentsAreaContainer{
    width: 100vw;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .TournamentsAreaContainer{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .TournamentsAreaContainer{
    width: 800px;
  }
}

@media (min-width: 1600px) {
  
  .TournamentsAreaContainer{
    width: 800px;
  }
}

.TournamentsAreaContainer_place{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100% - 36px);
  border-radius: .3rem;
  background: rgba(0, 0, 0, .3);
}

.TournamentsAreaContainer_anime{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  z-index: 0;
  animation: hide 3s forwards;
}