/* Countdown Timer */
.CountdownTimer{
  display: flex;
  justify-content: center;
  align-items: center;
  font: var(--countDownTimer);
  background: rgba(38, 1, 73, 0.3);
  color: #F8B54F;
  box-shadow: var(--box-shadow-signin);
  opacity: 0;
  border-radius: .4rem;
  cursor: default;
  position: absolute;
  animation: var(--logo-animation), show 12s forwards;
}

@media (max-width: 480px) {
  
  .CountdownTimer{
    width: 54px;
    height: 30px;
    left: 14px;
    top: -34px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .CountdownTimer{
    width: 54px;
    height: 30px;
    left: 14px;
    top: -34px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .CountdownTimer{
    width: 60px;
    height: 32px;
    left: 18px;
    top: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .CountdownTimer{
    width: 60px;
    height: 32px;
    left: 18px;
    top: 18px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .CountdownTimer{
    width: 60px;
    height: 32px;
    left: 18px;
    top: 18px;
  }
}

@media (min-width: 1600px) {
  
  .CountdownTimer{
    width: 60px;
    height: 32px;
    left: 18px;
    top: 18px;
  }
}

/* Countdown Timer */