@keyframes Loaing_infinity_wave{

  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }

}

@keyframes loader_simple_spinning{

  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }

}

@keyframes hexagonRun {

  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

}

@keyframes guestArrow{
  
  0%{
      opacity: 1
    } 
100%{
    opacity: 0;
    transform: translate(-10px, -10px)
    }

}

@keyframes hexagonBigRunFade {

  0% {
    background: var(--main-hex);
  }

  50% {
    background: var(--main-color);
  }

  100% {
    background: var(--main-wave);
  }

}

@keyframes hexagonBigRun {

  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

}

@keyframes jumpingAnimation {
  0% {
    transform: translate(0, 0);
  }
  16% {
    transform: translate(0, -15px);
  }
  33% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes LoaderCircleNumbers-rotate {

  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }

}

@keyframes Loader-loadingCircle {
  0% {
    -webkit-mask-size: 0    18px,0    18px,0    18px,auto
  }

  16.67% {
    -webkit-mask-size: 100% 18px,0    18px,0    18px,auto
  }

  33.33% {
    -webkit-mask-size: 100% 18px,100% 18px,0    18px,auto
  }

  50% {
    -webkit-mask-size: 100% 18px,100% 18px,100% 18px,auto
  }

  66.67% {
    -webkit-mask-size: 0    18px,100% 18px,100% 18px,auto
  }

  83.33% {
    -webkit-mask-size: 0    18px,0    18px,100% 18px,auto
  }

  100% {
    -webkit-mask-size: 0    18px,0    18px,0    18px,auto
  }
}

@keyframes AvatarEdittingPlace-blurFadeInOut {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px var(--main-wave);
		transform: scale(1.3);
	}
	20%, 75% {
		opacity: 1;
		text-shadow: 0px 0px 1px var(--main-wave);
		transform: scale(1);
	}
	100% {
		opacity: 0;
		text-shadow: 0px 0px 50px var(--main-wave);
		transform: scale(0);
	}
}
@keyframes AvatarEdittingPlace-blurFadeIn {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px var(--main-wave);
		transform: scale(1.3);
	}
	50% {
		opacity: 0.5;
		text-shadow: 0px 0px 10px var(--main-wave);
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
		text-shadow: 0px 0px 1px var(--main-wave);
		transform: scale(1);
	}
}

@keyframes LoaderCircleNumbers-percent {
  
  0% {
    content: '0%';
    cursor: progress;
  }

  3% {
    content: '3%';
    cursor: progress;
  }

  9% {
    content: '9%';
    cursor: progress;
  }

  14% {
    content: '14%';
    cursor: progress;
  }

  18% {
    content: '18%';
    cursor: progress;
  }

  25% {
    content: '25%';
    cursor: progress;
  }

  29% {
    content: '29%';
    cursor: progress;
  }

  33% {
    content: '33%';
    cursor: progress;
  }

  38% {
    content: '38%';
    cursor: progress;
  }

  42% {
    content: '42%';
    cursor: progress;
  }

  47% {
    content: '47%';
    cursor: progress;
  }

  51% {
    content: '51%';
    cursor: progress;
  }

  54% {
    content: '54%';
    cursor: progress;
  }

  57% {
    content: '57%';
    cursor: progress;
  }

  62% {
    content: '62%';
    cursor: progress;
  }

  69% {
    content: '69%';
    cursor: progress;
  }

  73% {
    content: '73%';
    cursor: progress;
  }

  75% {
    content: '75%';
    cursor: progress;
  }

  80% {
    content: '80%';
    cursor: progress;
  }

  86% {
    content: '86%';
    cursor: progress;
  }

  90% {
    content: '90%';
    cursor: progress;
  }

  95% {
    content: '95%';
    cursor: progress;
  }

  96% {
    content: '95%';
    cursor: progress;
  }

  97% {
    content: '95%';
    cursor: progress;
  }

  98% {
    content: '98%';
    cursor: progress;
  }

  99% {
    content: '99%';
    cursor: default;
  }

}