@keyframes PlayerGameRooms-createRoom {

  0% {
    bottom: 168px;
  }

  100% {
    bottom: 42px;
  }

}

@keyframes PlayerGameRooms-createRoom-triangle {

  0% {
    width: 228px;
    height: 210px; 
  }
  100% {
    width: 100%;
    height: 100%;   
  }

}

@keyframes PlayerGameRooms-createRoom-triangle-hide {

  0% { 
    opacity: 1; 
  }
  100% { 
    opacity: .044; 
  }

}

@keyframes PlayerGameRoomsBgrLight{

  0%{
   color: rgb(224, 204, 165);
   box-shadow: rgba(253, 124, 4, 0.5) 0px 54px 55px;
  }

  20%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(224, 111, 5, 0.5) 0px 54px 55px;
  }

  40%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(253, 115, 2, 0.5) 0px 54px 55px;
  }

  60%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(248, 122, 19, 0.5) 0px 54px 55px;
  }

  80%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(241, 91, 4, 0.5) 0px 54px 55px;
  }

  100%{
    color: rgb(204, 197, 184);
    box-shadow: rgba(243, 147, 4, 0.5) 0px 54px 55px;
  }

}

@keyframes BtnContainerPointerEvent {

  0% { 
    pointer-events: none;
  }
  
  100% { 
    pointer-events: auto;
  }

}