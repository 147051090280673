.NavPlace{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 780px;
  height: 100%;
  background: var(--grey-light);
  box-shadow: var(--box-shadow-features);
  border-radius: 10px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  animation: show .2s forwards;
  z-index: 3;
}

@media (max-width: 480px) {
  
  .NavPlace{
    min-width: 350px;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .NavPlace{
    width: 100vw;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .NavPlace{
    width: 100%;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .NavPlace{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .NavPlace{
    width: 800px;
  }
}

@media (min-width: 1600px) {
  
  .NavPlace{
    width: 800px;
  }
}

