/* all rooms */
.everySingleRoom{
  display: flex;
  justify-content: center;
  align-items: self-start;
  border: 2px solid var(--brown-first);
  border-radius: .2rem;
  color: var(--main-nav);
  box-shadow: var(--box-shadow-signin);
  opacity: 0.4;
  transition: .2s all;
  position: relative;
  animation: showFull .1s forwards, gameRoomsBackgroundColor 44s infinite alternate;
  z-index: 5;
 }

 @media (max-width: 480px) {
  
  .everySingleRoom{
    width: 120px;
    height: 92px;
    margin: .1rem .2rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .everySingleRoom{
    width: 138px;
    height: 92px;
    margin: .1rem .8rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .everySingleRoom{
    width: 138px;
    height: 108px;
    margin: .1rem 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .everySingleRoom{
    width: 144px;
    height: 112px;
    margin: .1rem 1rem;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .everySingleRoom{
    width: 144px;
    height: 112px;
    margin: .1rem 1rem;
  }
}

@media (min-width: 1600px) {
  
  .everySingleRoom{
    width: 144px;
    height: 112px;
    margin: .1rem 1rem;
  }
}

 .everySingleRoom:hover{
  border: 2px solid var(--brown);
  box-shadow: var(--box-shadow-features);
  transform: scale(1.001, 1.001);
 }

 .everySingleRoom::before{
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  background: rgba(0, 0, 0, .3);
  border-bottom: 2px solid grey;
  border-radius: .2rem;
  box-shadow: var(--box-shadow-features);
  position: absolute;
  z-index: 1;
 }

@media (max-width: 767px) {

  .everySingleRoom::before{
    width: 130px;
    height: 30px;
  }
}

 .everySingleRoom::after{
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 50px;
  background: rgba(0, 0, 0, .4);
  border-top: 2px solid grey;
  border-radius: .2rem;
  box-shadow: var(--box-shadow-features);
  position: absolute;
  top: 57px;
  z-index: 1;
 }

 @media (max-width: 767px) {
  
  .everySingleRoom::after{
    width: 130px;
    height: 30px;
  }
}

 .everySingleRoom-numberplace{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 34px; 
  background: rgba(0, 0, 0, .5);
  position: relative;
 }

 .everySingleRoom-number{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 30px;
  overflow: hidden;
  font: bold .8rem 'Raleway', sans-serif;
  position: absolute;
  cursor: default;
 }

 @media (max-width: 767px) {
  
  .everySingleRoom-number{
    height: 28px; 
    font: bold .74rem 'Raleway', sans-serif;
  }
}

 .everySingleRoom-hex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; 
  border: 2px solid rgba(93, 12, 185, .28);
  box-shadow: var(--box-shadow-menu);
  overflow: hidden;
  position: absolute;
  z-index: -1;
 }

 @media (max-width: 767px) {
  
  .everySingleRoom-hex{
    height: 92px;
  }
}

 .everySingleRoom-available{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 10px;
  background: var(--on-green);
  box-shadow: var(--box-shadow-features);
  border-radius: .2rem;
  position: absolute;
  bottom: 53px;
  z-index: 1;
 }

 .everySingleRoom-notAvailable{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 10px;
  background: var(--off-red);
  box-shadow: var(--box-shadow-features);
  border-radius: .2rem;
  position: absolute;
  bottom: 50px;
  z-index: 1;
 }

 @media (max-width: 767px) {

  .everySingleRoom-available, .everySingleRoom-notAvailable{

    bottom: 40px;
  }
}

 /* room buttons */
 .everySingleRoom-btn-place{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 8px;
  z-index: 2;
 }

 @media (max-width: 767px) {
  
  .everySingleRoom-btn-place{
    bottom: 2px;
  }
}

.everySingleRoom-btn-place_button {
  background: var(--main-wave-trans);
  color: var(--white-second);
  width: 120px;
  height: 36px;
  border: 2px solid transparent;
  border-radius: .3rem;
  transition: all 0.6s ease;
  cursor: pointer;
}

@media (max-width: 480px) {
  
  .everySingleRoom-btn-place_button{
    width: 92px;
    height: 24px;
    text-align: center;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .everySingleRoom-btn-place_button{
    width: 92px;
    height: 24px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .everySingleRoom-btn-place_button{
    width: 108px;
    height: 32px;
    text-align: right;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .everySingleRoom-btn-place_button{
    width: 120px;
    height: 36px;
    text-align: right;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .everySingleRoom-btn-place_button{
    width: 120px;
    height: 36px;
    text-align: right;
  }
}

@media (min-width: 1600px) {
  
  .everySingleRoom-btn-place_button{
    width: 120px;
    height: 36px;
    text-align: right;
  }
}

.everySingleRoom-btn-place_button:hover {
  background: var(--brownish);
}

.everySingleRoom-btn-place_button svg {
  width: 26px;
  margin: -5px 12px 16px;
  pointer-events: none;
  position: absolute;
  display: flex;
  transition: all 0.6s ease;
}

.everySingleRoom-btn-place_button:hover svg {
  transform: translateX(5px);
}

@media (max-width: 767px) {
  
  .everySingleRoom-btn-place_button svg {
    display: none;
  }
}

@media (max-width: 767px) {
  
  .everySingleRoom-btn-place_button_text {
    pointer-events: none;
  }
}

@media (min-width: 768px) {
  
  .everySingleRoom-btn-place_button_text {
    pointer-events: none;
    margin: 0 16px
  }
}

/* room buttons */

/* side arrows */
.singleRooms-sideArrow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: calc(100% - 2px);
  height: 80px;
  opacity: 0;
  position: absolute;
  bottom: calc(50% - 40px);
  animation: show 3.5s forwards;
  z-index: 4;
 }

 .singleRooms-sideArrow-left, .singleRooms-sideArrow-right{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: var(--brown-first-trans);
  border-radius: 50%;
  box-shadow: var(--box-shadow-features);
  cursor: pointer;
  position: relative;
  animation: pointerEvent 4.2s forwards;
 }

 .singleRooms-sideArrow-img{
  width: 48px;
  height: 48px;
  background: var(--brown);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .3s ;
 }

 .singleRooms-sideArrow-img:hover{
  transform: translate(-50%, -50%) scale(1.001, 1.001);
  border-radius: 50%;
  background: var(--main-color);
 }
 /* side arrows */
