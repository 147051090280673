.MultiRoomDisable{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.MultiRoomDisable-notEnter{
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 80px);
  height: calc(100% - 14px);
  border-radius: .2rem;
  background: var(--black-light);
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  z-index: 3;
  animation: MultiRoomDisableEvent .6s 1s forwards, MultiRoomDisableShow 1.4s forwards;
 }

 .MultiRoomDisable-notEnter::after{
  content: "You cannot access this room!";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  width: 320px;
  height: 58px;
  border: 4px solid var(--forth-nav);
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  padding: .1rem;
  background: var(--main-wave);
  opacity: 0;
  color: var(--forth-nav);
  position: absolute;
  top: 4px;
  font: bolder .8rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-extra);
  animation: show .4s forwards;
 }