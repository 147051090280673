@keyframes goldenLogoBgr {

  0%{
    background-position : 0%;
    box-shadow: 0 0 .8em #7A3E07;
  }
  50%{
    background-position: 60%;
    box-shadow: 0 0 .8em #F3C343;
  }
  100%{
    background-position: 100%;
    box-shadow: 0 0 .8em #C1872B;
  }

}

@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; 
  } 
}

@keyframes gravity {

  to {
    transform: translateY(200px);
    opacity: 0; 
  } 

}

@keyframes position {
  
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 10%; 
  }

  20%, 39.9% {
    margin-top: 10%;
    margin-left: 20%; 
  }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 30%; 
  }

  60%, 79.9% {
    margin-top: 20%;
    margin-left: 30%; 
  }

  80%, 99.9% {
    margin-top: 20%;
    margin-left: 20%; 
  } 
}