.AvatarLevelCard{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (max-width: 480px) {

  .AvatarLevelCard{
    width: 100%;
    height: 22px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .AvatarLevelCard{
    width: 418px;
    height: 48px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .AvatarLevelCard{
    width: 418px;
    height: 48px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .AvatarLevelCard{
    width: 100%;
    height: 60px;
    border-radius: .2rem;
    background: var(--grey-simple);
    box-shadow: var(--box-shadow-menu);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .AvatarLevelCard{
    width: 100%;
    height: 60px;
    border-radius: .2rem;
    background: var(--grey-simple);
    box-shadow: var(--box-shadow-menu);
  }
}

@media (min-width: 1600px) {
  
  .AvatarLevelCard{
    width: 100%;
    height: 60px;
    border-radius: .2rem;
    background: var(--grey-simple);
    box-shadow: var(--box-shadow-menu);
  }
}

.AvatarLevelCard_place{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  /* border: 2px solid rgb(255, 153, 0); */
  border-radius: .4rem;
  position: relative;
}

@media (max-width: 480px) {
  
  .AvatarLevelCard_place{
    width: 68px;
    height: 20px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .AvatarLevelCard_place{
    width: 68px;
    height: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .AvatarLevelCard_place{
    width: 120px;
    height: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .AvatarLevelCard_place{
    width: 160px;
    height: 40px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .AvatarLevelCard_place{
    width: 160px;
    height: 40px;
  }
}

@media (min-width: 1600px) {
  
  .AvatarLevelCard_place{
    width: 160px;
    height: 40px;
  }
}

.AvatarLevelCard_place h4{
  color: var(--main-hex);
  font: bold .84rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-simple);
}

.AvatarLevelCard_playedGames{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 180px;
  height: 40px;
  cursor: default;
  position: absolute;
  left: 2px;
}

.AvatarLevelCard_playedGames h4{
  color: var(--main-hex);
  font: bold .68rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-simple);
}