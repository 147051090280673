.Runners_container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 786px;
  height: 70vh;
  background: rgba(0, 0, 0, .3);
  border-radius: 2rem;
  position: relative;
  z-index: 2;
}

.Runners_item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 760px;
  height: 200px;
  background: rgba(0, 0, 0, .78);
  box-shadow: var(--box-shadow-features);
  border-radius: 2rem;
  position: relative;
  z-index: 3;
}

.Runners_item::after{
  content: '';
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  height: 380px;
  background: rgba(0, 0, 0, .78);
  box-shadow: var(--box-shadow-features);
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  position: absolute;
  top: 190px;
  z-index: 1;

}

.content {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content h2 {
  font: var(--Runners-Font);
  text-shadow: var(--text-shadow-simple);
  position: absolute;
  transform: translate(-50%, -50%);
}

.content h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px var(--brown);
}

.content h2:nth-child(2) {
  color: var(--main-wave);
  animation: Runners 4s ease-in-out infinite;
}
