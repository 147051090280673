h2 {
  font: var(--signin-form-first);
  color: var(--white-second);
  padding: .5rem 1rem;
  text-shadow: var(--text-shadow-simple);
  cursor: default;
}

.form-err{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  height: auto;
  position: absolute;
  z-index: 3;
}

@media (max-width: 699px) {
  
  .form-err{
    display: none;
  }
}

@media (min-width: 700px) {
  
  .form-err{
    top: 20px;
    left: 250px;
  }
}

.userName_err_display, .email_err_display, 
.password_err_display, .confirmedPassword_err_display{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: auto;
  background: rgba(255, 255, 255, .8);
  border-radius: .4rem;
  padding: .5rem;
  margin: .1rem;
  font-size: .8rem;
}

.edit-form-err{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  height: auto;
  position: absolute;
  z-index: 3;
  bottom: 200px;
  left: -140px;
}

.edit-userName_err_display, .edit-email_err_display, 
.edit-password_err_display, .edit-confirmedPassword_err_display{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: auto;
  background: rgba(255, 255, 255, .8);
  border-radius: .4rem;
  padding: .5rem;
  margin: .1rem;
  font-size: .8rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
}

.inputBox {
  position: relative;
  width: 100%;
}

input {
  width: 100%;
  background: var(--grey-second);
  color: var(--white);
  border: 2px solid transparent;
  outline: none;
  border-radius: 0.3rem;
  padding: 25px 10px 7.5px;
  font: var(--signin-form-second);
  transition: .33s;
  position: relative;
}

.inputBox i {
  font: var(--signin-form-third);
  color: var(--white-first);
  padding: 15px 10px;
  transition: 0.5s ease all;
  pointer-events: none;
  position: absolute;
  transform: translateY(-5px);
  left: 0;
}

.inputBox input:focus{
  border: 2px solid var(--grey-light);
}

.inputBox input:focus ~ i,
.inputBox input:not(:focus):valid ~ i,
.inputBox input:valid ~ i{
  transform: translateY(-32px);
  /* font-size: .6rem; */
}

.form .input_links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* font-family: 'Raleway', sans-serif;  */
  font: var(--signin-form-second);
  position: relative;
}

.input_links p {
  font: var(--signin-form-forth);
  color: black;
  cursor: default;
}

.input_links a {
  font: var(--signin-form-fifth);
  text-decoration: none;
  color: var(--main-color-third);
}

.input_links a:visited{
  color: var(--main-color-third);
}

.input_links a:active{
  color: var(--main-color-second);
}

.input_links a:hover{
  color: var(--white-second);
}

.submit_BTN{
  width: 100%;
  background: var(--grey-second);
  color: var(--white);
  border: 2px solid transparent;
  outline: none;
  border-radius: 0.3rem;
  padding: 25px 10px 7.5px;
  font: var(--signin-form-second);
  transition: .33s;
  position: relative;
  background: black;
  color: wheat;
  font: var(--signin-submit);
  letter-spacing: 0.05rem;
  padding: 10px;
  cursor: pointer;
}

.submit_BTN:hover{
  color: var(--white);
}

.submit_BTN:active{
  opacity: 0.75;
}

.submit_BTN_NoneClick{
  width: 100%;
  background: var(--grey-second);
  color: var(--white);
  border: 2px solid transparent;
  outline: none;
  border-radius: 0.3rem;
  padding: 25px 10px 7.5px;
  font: var(--signin-form-second);
  transition: .33s;
  position: relative;
  background: black;
  color: wheat;
  font: var(--signin-submit);
  letter-spacing: 0.05rem;
  padding: 10px;
  cursor: pointer;
  pointer-events: none;
}

.submit_BTN_NoneClick:hover{
  color: var(--white);
}
