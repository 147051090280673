@keyframes MultiRoomDisableEvent {

  0% { 
    pointer-events:none;
  }
  100% { 
    pointer-events:auto;
  }

}

@keyframes MultiRoomDisableShow {

  0% {
    transform: scale(0)
  }
  100% {
    transform: scale(1);
  }

}