.AvatarFeatures{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 50px;
  height: 180px;
  position: absolute;
}

@media (max-width: 480px) {
 
  .AvatarFeatures{
    bottom: 28px;
    right: 4px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .AvatarFeatures{
    bottom: 42px;
    right: 4px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .AvatarFeatures{
    bottom: 124px;
    right: 48px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .AvatarFeatures{
    bottom: 124px;
    right: 42px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {

  .AvatarFeatures{
    bottom: 124px;
    right: 34px;
  }
}

@media (min-width: 1600px) {
  
  .AvatarFeatures{
    bottom: 124px;
    right: 48px;
  }
}

/* feature buttons, coin, settings, edit and log out */
.AvatarFeatureItems-btn-coin{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: var(--on-green);
}

.AvatarFeatureItems-btn-settings{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: var(--settings-color);
}

.AvatarFeatureItems-btn-edit{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: var(--secondary-color);
}

.AvatarFeatureItems-btn-logout{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: var(--main-wave);
}

/* plus sign */
.AvatarFeatureItems-btn-coin-sign, .AvatarFeatureItems-btn-settings-sign, .AvatarFeatureItems-btn-edit-sign, .AvatarFeatureItems-btn-logout-sign{
  width: 100%;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.AvatarFeatureItems-btn-coin-sign svg, .AvatarFeatureItems-btn-settings-sign svg,  .AvatarFeatureItems-btn-edit-sign svg, .AvatarFeatureItems-btn-logout-sign svg{
  width: 18px;
  pointer-events: none;
}

.AvatarFeatureItems-btn-coin-sign svg path, .AvatarFeatureItems-btn-settings-sign svg path, .AvatarFeatureItems-btn-edit-sign svg path, .AvatarFeatureItems-btn-logout-sign svg path   {
  fill: white;
}
/* text */
        
.AvatarFeatureItems-btn-coin-text, .AvatarFeatureItems-btn-settings-text, .AvatarFeatureItems-btn-edit-text, .AvatarFeatureItems-btn-logout-text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: .8rem;
  font-weight: 600;
  transition-duration: .3s;
  pointer-events: none;
}

@media (min-width: 992px) {

  /* hover effect on button width */
  .AvatarFeatureItems-btn-coin:hover, .AvatarFeatureItems-btn-settings:hover, .AvatarFeatureItems-btn-edit:hover, .AvatarFeatureItems-btn-logout:hover {
    width: 125px;
    border-radius: 40px;
    transition-duration: .3s;
  }

  .AvatarFeatureItems-btn-coin:hover .AvatarFeatureItems-btn-coin-sign,  .AvatarFeatureItems-btn-settings:hover .AvatarFeatureItems-btn-settings-sign, .AvatarFeatureItems-btn-edit:hover .AvatarFeatureItems-btn-edit-sign, .AvatarFeatureItems-btn-logout:hover .AvatarFeatureItems-btn-logout-sign{
    width: 30%;
    transition-duration: .3s;
    padding-left: 20px;
  }

  /* hover effect button's text */
  .AvatarFeatureItems-btn-coin:hover .AvatarFeatureItems-btn-coin-text, .AvatarFeatureItems-btn-settings:hover .AvatarFeatureItems-btn-settings-text, .AvatarFeatureItems-btn-edit:hover .AvatarFeatureItems-btn-edit-text, .AvatarFeatureItems-btn-logout:hover .AvatarFeatureItems-btn-logout-text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
  }
}

/* button click effect*/
.AvatarFeatureItems-btn-coin:active, .AvatarFeatureItems-btn-settings:active, .AvatarFeatureItems-btn-edit:active, .AvatarFeatureItems-btn-logout:active {
  transform: translate(2px ,2px);
}

/* feature buttons, coin, settings, edit and log out */