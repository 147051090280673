@keyframes createRoom-Bgr 

{
  0%
  {
    transform: scale(.1)
  }
  100%
  {
    transform: scale(1);
  }
}

@keyframes createRoom-Bgr-move 
{
  0%
  {
    transform: translateY(-100%);
  }
  100%
  {
    transform: translateY(100%);
  }
}