@keyframes OpponentTurnPauseDarkness {

  0% { 
    background: rgba(5, 27, 224, 0.198);
  }
  100% { 
    background: rgba(0, 5, 46, 0.84);
  }

}

@keyframes OpponentTurnSpinner {
  
  0%  {
    transform: rotate(0deg);
  }
  100% {transform: rotate(360deg);
  }   
  
}