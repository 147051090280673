.ToYourRoom {
  cursor: pointer;
  border: 2px solid var(--main-color-second);
  padding: .6rem 1.2rem;
  margin-top: .5rem;
  color: var(--white-second);
  font-size: 12px;
  font-weight: bold;
  background-color: var(--grey-first);
  border-radius: 1rem;
  outline: none;
  box-shadow: var(--box-shadow-createRoom-1);
  text-shadow: 0 0 .5em var(--main-color-first);
  transition: all 0.3s;
  position: absolute;
  animation: PlayerGameRoomsByLevel .2s 6s forwards;
  z-index: 5;
 }
 
.ToYourRoom::after {
  content: "";
  background-color: var(--main-color-third-trans);
  opacity: .7;
}
 
.ToYourRoom:hover {
  color: var(--main-wave);
  background-color: var(---main-color-first);
  box-shadow: var(--box-shadow-createRoom-2);
}
 
.ToYourRoom:active {
  box-shadow: var(--box-shadow-createRoom-3);
}