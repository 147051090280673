.userOrGuest{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  background: var(--grey);
  /* border: 2px solid var(--black); */
  border-radius: 50%;
  padding: .3rem;
  box-shadow: var(--box-shadow-menu);
  position: relative;
}

.show_loggedUser_username::after{
  content: attr(data);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 24px;
  background: var(--forth-nav);
  color: var(--main-color-second);
  opacity: .68;
  border-radius: .2rem;
  font: var(--logged-userName);
  pointer-events: all;
  position: absolute;
}

@media (max-width: 480px) {
  
  .show_loggedUser_username::after{
    width: 96px;
    height: 24px;
    top: 0px;
    right: -2px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .show_loggedUser_username::after{
    width: 96px;
    height: 24px;
    top: 0px;
    right: -2px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .show_loggedUser_username::after{
    width: 96px;
    height: 24px;
    top: 0px;
    right: -4px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .show_loggedUser_username::after{
    width: 96px;
    height: 24px;
    top: 0px;
    right: 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .show_loggedUser_username::after{
    width: 96px;
    height: 24px;
    top: 0px;
    right: 0px;
  }
}

@media (min-width: 1600px) {
  
  .show_loggedUser_username::after{
    width: 96px;
    height: 24px;
    top: 0px;
    right: -26px;
  }
}

.show_loggedUser_username:hover::after{
  opacity: 1;
}

#userPlace{
  width: 28px;
  height: 28px;
  opacity: .8;
  cursor: pointer;
}

#userPlace:hover{
  width: 28px;
  height: 28px;
  opacity: 1;
  cursor: pointer;
}
