.AvatarImageCard{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .2rem;
  background: var(--grey-simple);  
  opacity: 1;
  box-shadow: var(--box-shadow-menu);
  cursor: default;
  position: relative;
  animation: showFull 3s forwards;
}

@media (max-width: 480px) {
  
  .AvatarImageCard{
    flex-direction: row;
    width: 302px;
    height: 112px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .AvatarImageCard{
    flex-direction: row;
    width: 412px;
    height: 132px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .AvatarImageCard{
    flex-direction: row;
    width: 454px;
    height: 132px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .AvatarImageCard{
    flex-direction: column;
    width: 165px;
    height: 200px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .AvatarImageCard{
    flex-direction: column;
    width: 165px;
    height: 200px;
  }
}

@media (min-width: 1600px) {
  
  .AvatarImageCard{
    flex-direction: column;
    width: 165px;
    height: 200px;
  }
}

.AvatarImageCard #avatar_image{
  width: 110px;
  border: 4px solid var(--third-color);
  border-radius: .4rem;
  background: var(--brown-second-trans);
  margin-top: .2rem;
}

@media (max-width: 480px) {
  
  .AvatarImageCard #avatar_image{
    width: 84px;
    margin-right: 1.4rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .AvatarImageCard #avatar_image{
    width: 102px;
    margin-right: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .AvatarImageCard #avatar_image{
    width: 110px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .AvatarImageCard #avatar_image{
    width: 110px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .AvatarImageCard #avatar_image{
    width: 110px;
  }
}

@media (min-width: 1600px) {
  
  .AvatarImageCard #avatar_image{
    width: 110px;
  }
}

.avatar_userinfo_card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 120px;
  height: 100px;
  border-radius: .2rem;
  color: #fff;
  opacity: .8;
}

.avatar_userinfo_card h3{
  font-size: .8em;
  font-weight: normal;
  font-family: 'Raleway', sans-serif;
  color: var(--white-first);
  margin: .2rem;
}

.avatar_userinfo_cardEmph{
  font-weight: bold;
  color: var(--main-wave);
}