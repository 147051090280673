/* Create Game Room Container */
.BtnContainerToGoCreateRoom {
	display: flex;
	justify-content: center;
	align-items: center;
  width: calc(100% - 44px);
  height: 44px; 
  opacity: 0;
  position: absolute;
  bottom: 168px;
  pointer-events: none;
  animation: show 3s forwards, PlayerGameRooms-createRoom .8s ease-in 3s forwards, BtnContainerPointerEvent .2s 6s forwards;;
  z-index: 1;
  /* border: 2px solid red; */
}

.BtnContainerToGoCreateRoom-loading{
  display: flex;
	justify-content: center;
	align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  animation: show 2s 3.4s forwards, hide 1s 5s forwards;
  z-index: 2;
}

/* triangle */
.BtnContainerToGoCreateRoom-triangle{
  display: flex;
	justify-content: center;
	align-items: center;
  width: 180px;
  height: 12px;
  border-radius: .4rem;
  background: rgba(0, 0, 0, .06);
  position: absolute;
  animation: PlayerGameRooms-createRoom-triangle .4s ease-in 3s forwards;
  z-index: 3;
}

.color-triangle {
	background: rgb(0,0,0);
  position: absolute;
  animation: PlayerGameRoomsBgrLight 2s forwards, PlayerGameRooms-createRoom-triangle-hide .4s 3.5s forwards;
}

/*triangle left */
#BtnContainerToGoCreateRoom-triangle-one {
  width: 20px;
	height: 150px;
	background: var(--quote-bgr);
	transform: skewX(-30deg) translate(-245%,-10%);
}

/*triangle bottom */
#BtnContainerToGoCreateRoom-triangle-two {
  width: 180px;
	height: 18px;
  background: var(--guest-content);
	transform: skewX(30deg) translate(-32%, 430%);
}

/*triangle right */
#BtnContainerToGoCreateRoom-triangle-three {
	height: 150px;
	width: 20px;
	background: var(--guest-front);
	transform: skew(30deg) skewY(-40deg) translate(280%, 35%);
}
/* triangle */

/* create room button */
.BtnContainerToGoCreateRoom-create-area{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%; 
  border-radius: .2rem;
  opacity: 0;
  position: absolute;
  background: rgba(0, 0, 0, .144);
  animation: show 7s forwards;
  position: relative;
  z-index: 4;
}

.BtnContainerToGoCreateRoom-create-area::after{
  
  content: "";
	display: flex;
	justify-content: center;
  width: 100%;
  height: 100%; 
	align-items: center;
  position: absolute;
  opacity: 1;
  z-index: 0;
}

/* Create Room Button */
.BtnContainerToGoCreateRoom-create {
  cursor: pointer;
  border: 2px solid var(--main-color-second);
  padding: .56rem 1.2rem;
  color: var(--white-second);
  font-size: 12px;
  font-weight: bold;
  background-color: var(--grey-first);
  border-radius: 1rem;
  outline: none;
  box-shadow: var(--box-shadow-createRoom-1);
  text-shadow: 0 0 .5em var(--main-color-first);
  transition: all 0.3s;
  position: absolute;
  left: .2rem;
  z-index: 5;
 }
 
.BtnContainerToGoCreateRoom-create::after {
  content: "";
  background-color: var(--main-color-third-trans);
  opacity: .7;
}
 
.BtnContainerToGoCreateRoom-create:hover {
  color: var(--main-wave);
  background-color: var(---main-color-first);
  box-shadow: var(--box-shadow-createRoom-2);
}
 
.BtnContainerToGoCreateRoom-create:active {
  box-shadow: var(--box-shadow-createRoom-3);
}
/* Create Room Button */
 /* Create Game Room Container */

 /* Empty and full rooms switch */
.BtnContainerToGoCreateRoom-switch {
  display: flex;
  width: 120px;
  height: 30px;
  position: absolute;
  z-index: 1;
  right: .2rem;
}
 
.BtnContainerToGoCreateRoom-switch input {
  display: none;
}
 
.BtnContainerToGoCreateRoom-slider {
  cursor: pointer;
  background-color: var(--grey-second);
  transition: .4s;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
 
.BtnContainerToGoCreateRoom-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 22px;
  left: 4px;
  bottom: 5px;
  background-color: var(--white-second);
  transition: .4s;
  border-radius: 50%;
}
 
.BtnContainerToGoCreateRoom-switch input:checked + .BtnContainerToGoCreateRoom-slider {
  background-color: var(--brown-second);
 }
  
.BtnContainerToGoCreateRoom-switch input:checked + .BtnContainerToGoCreateRoom-slider:before {
  transform: translateX(90px);
 }
 
.BtnContainerToGoCreateRoom-slider:after {
  content: 'All Rooms';
  display: flex;
  color: var(--white-second);
  font-size: 10px;
  font-family: Verdana, sans-serif;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}
 
.BtnContainerToGoCreateRoom-switch input:checked + .BtnContainerToGoCreateRoom-slider:after {
  content: 'Unoccupied';
}
 /* Empty and full rooms switch */