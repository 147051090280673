.SwitchGameTypes{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 30px;
  background: rgb(0, 0, 0, .75);
  color: #fff;
  border-top-left-radius: .6rem;
  border-top-right-radius: .6rem;
  position: absolute;
  bottom: 2px;
  z-index: 5;
}

.SwitchGameTypes a:link{
  font: bold .72rem 'Raleway', sans-serif;
  text-decoration: none;
  margin: .6rem;
  color: var(--main-color-third);
}

.SwitchGameTypes a:visited{
  color: var(--main-color-third);
}

.SwitchGameTypes a:hover{
  color: var(--main-color-first);
}


.SwitchGameTypes-selected{
  color: var(--main-color-first)!important; 
}