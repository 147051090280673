@keyframes PlayOrWatchArea-198-Bgr {

  0%{
    background-position : 0%;
    box-shadow: 0 0 .8em #201000;
  }
  50%{
    background-position: 60%;
    box-shadow: 0 0 .8em #1a0d00;
  }
  100%{
    background-position: 100%;
    box-shadow: 0 0 .8em #110900;
  }

}