.EditFormGeneral{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  width: auto;
  border-radius: .2rem;
  cursor: default;
  opacity: 1;
  position: relative;
  /* animation: var(--forms); */
}

.EditFormGeneral-LoaderSimple{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
  align-items: center;
  position: absolute;
  animation: hide .8s forwards;
}

.EditUserName{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 188px;
  background: var(--grey-simple);  
  opacity: 1;
  padding: 2rem;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: relative;
  z-index: 1;
  animation: show .4s forwards, editEvent 1.6s forwards, editShow 1.1s forwards;;
}

.EditEmail{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 188px;
  background: var(--grey-simple); 
  opacity: 1;
  padding: 2rem;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: relative;
  z-index: 1;
  animation: show .4s forwards, editEvent 1.6s forwards, editShow 1.1s forwards;
}

.EditPassword{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 262px;
  background: var(--grey-simple);  
  opacity: 1;
  padding: 2rem;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: relative;
  z-index: 1;
  animation: show .4s forwards, editEvent 1.6s forwards, editShow 1.1s forwards;
}

.Edit-hide{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 262px;
  opacity: 1;
  padding: 40px;
  position: relative;
  z-index: 1;
}

.EditUserName_content, .EditEmail_content, .EditPassword_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 40px;
  position: relative;
}