.ContinentalCup{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  border-radius: .4rem;
  position: absolute;
}

.ContinentalCup_loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: .4rem;
  background: rgba(0, 0, 0, .5);
  box-shadow: var(--box-shadow-features);
  position: absolute;
  animation: hide 3s forwards, ContinentalCupCursorNone .9s 2.2s forwards;
  z-index: 3;
}

.ContinentalCup_ContinentList_show{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.ContinentList-map {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  opacity: 0;
  position: relative;
  animation: show 3.3s forwards;
  z-index: 1;
}

@media (max-width: 480px) {
  
  .ContinentList-map > img{
    width: calc(100% - 32px);
    min-width: 328px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .ContinentList-map > img{
    width: calc(100% - 32px);
    max-width: 618px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .ContinentList-map > img{
    width: 618px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .ContinentList-map > img{
    width: 618px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .ContinentList-map > img{
    width: 618px;
  }
}

@media (min-width: 1600px) {
  
  .ContinentList-map > img{
    width: 618px;
  }
}

.ContinentList-map-point {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0.8;
  background: rgba(26, 26, 26, 0.85);
  transform: translate(-50%, -50%);
  cursor: pointer;
  position: absolute;
}

@media (max-width: 480px) {
  
  .ContinentList-map-point{
    width: 28px;
    height: 28px;
    border: 2px solid var(--white-first);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .ContinentList-map-point{
    width: 32px;
    height: 32px;
    border: 3px solid var(--white-first);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .ContinentList-map-point{
    width: 36px;
    height: 36px;
    border: 4px solid var(--white-first);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .ContinentList-map-point{
    width: 40px;
    height: 40px;
    border: 5px solid var(--white-first);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .ContinentList-map-point{
    width: 40px;
    height: 40px;
    border: 5px solid var(--white-first);
  }
}

@media (min-width: 1600px) {
  
  .ContinentList-map-point{
    width: 40px;
    height: 40px;
    border: 5px solid var(--white-first);
  }
}

.ContinentList-map-point_tooltip{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 202px;
  height: 38px;
  border: 5px solid var(--main-hex);
  border-radius: .4rem;
  background: rgba(0, 0, 0, .8);
  color: var(--white-second);
  opacity: 0;
  position: absolute;
  top: -40px;
  transition: opacity .25s ease-in-out;
  pointer-events: none;
}

.ContinentList-map-point_northAmerica{
  top: 37%; 
  left: 25%;
}

.ContinentList-map-point_southAmerica{
  top: 70%; 
  left: 35%
}

.ContinentList-map-point_europe{
  top: 35%; 
  left: 51%
}

.ContinentList-map-point_africa{
  top: 60%; 
  left: 51%
}

.ContinentList-map-point_asia{
  top: 35%; 
  left: 70%
}

.ContinentList-map-point_australia{
  top: 76%; 
  left: 77%
}

.ContinentList-map-point_northAmerica:hover .ContinentList-map-point_tooltip,
.ContinentList-map-point_southAmerica:hover .ContinentList-map-point_tooltip,
.ContinentList-map-point_europe:hover .ContinentList-map-point_tooltip,
.ContinentList-map-point_africa:hover .ContinentList-map-point_tooltip,
.ContinentList-map-point_asia:hover .ContinentList-map-point_tooltip,
.ContinentList-map-point_australia:hover .ContinentList-map-point_tooltip{
  opacity: 1;
}

.Continent{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.singleContinent-container {
  height: 240px;
  opacity: 0;
  border: 3px solid var(--main-color-first);
  border-radius: 10px;
  box-shadow: var(--box-shadow-menu);
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: show 4s forwards;
  z-index: 1;
}

@media (max-width: 480px) {
  
  .singleContinent-container{
    width: 340px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .singleContinent-container{
    width: calc(100% / 1.2);
    max-width: 460px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .singleContinent-container{
    width: calc(100% / 1.4);
    max-width: 500px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .singleContinent-container{
    width: calc(100% / 1.6);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .singleContinent-container{
    width: calc(100% / 1.6);
  }
}

@media (min-width: 1600px) {
  
  .singleContinent-container{
    width: calc(100% / 1.6);
  }
}

.singleContinent {
  width: 100%;
  height: 100%;
  border-radius: .3rem;
  
}

.singleContinent .singleContinent_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  height: 100%;
  gap: 10px;
  background: var(--guest-content);
  color: var(--black);
  padding: 12px;
  line-height: 1.5;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.singleContinent .singleContinent_content .singleContinent_heading {
  font: bolder 1.2rem 'Raleway', sans-serif;;
}

.singleContinent_selected_loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  position: absolute;
  z-index: 3;
  animation: hide 3.2s forwards, ContinentalCupCursorNone .9s 2.8s forwards;
}

.ContinentButtons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

.ContinentButtons_button {
  background-color: transparent;
  color: white;
  width: 144px;
  height: 44px;
  border: 0.2rem solid transparent;
  border-radius: .3rem;
  margin: 0 1rem;
  text-align: center;
  transition: all 0.6s ease;
  cursor: pointer;
}

.ContinentButtons_button:hover {
  background-color: black;
}

.ContinentButtons_button_text {
  pointer-events: none;
}