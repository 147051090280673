.OpponentTurnPause-right-userPlace {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 28px;
  background: var(--forth-nav);
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.OpponentTurnPause-right-userPlaceLink{
  font: bolder .74rem 'Raleway', sans-serif;
  color: var(--main-color);
  text-decoration: none;
  text-shadow: var(--text-shadow-simple);
}

.OpponentTurnPause-right-userPlaceLink:visited {
  color: var(--main-color);
}

.OpponentTurnPause-right-userPlaceLink:hover {
  color: var(--main-nav);
}

.OpponentTurnPause-right-userPlaceLink:active{
  color: var(--main-nav);
}