.LeftSide, .RightSide{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 480px) {
  
  .LeftSide, .RightSide{
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  .LeftSide, .RightSide{
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .LeftSide, .RightSide{
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .LeftSide, .RightSide{
    width: 96px;
  }
}


@media (min-width: 1200px) and (max-width: 1599px) {
  
  .LeftSide, .RightSide{
    width: 194px;
  }
}

@media (min-width: 1600px) {

  .LeftSide, .RightSide{
    width: 200px;
  }
}