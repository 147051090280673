.TournamentsArea{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: show 3.6s forwards;
  
}

.TournamentsArea::after{
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100%  / 1.4);
  background: var(--secondary-color-first);
  border-radius: .4rem;
  box-shadow: var(--box-shadow-features);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

@media (max-width: 480px) {
  
  .TournamentsArea::after{
    width: 100%;
    min-width: 350px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .TournamentsArea::after{
    width: 100%;
    max-width: 620px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .TournamentsArea::after{
    width: 620px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .TournamentsArea::after{
    width: 620px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .TournamentsArea::after{
    width: 620px;
  }
}

@media (min-width: 1600px) {
  
  .TournamentsArea::after{
    width: 620px;
  }
}

@media (max-width: 480px) {
  
  .TournamentsArea-img {
    width: 268px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .TournamentsArea-img {
    width: 280px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .TournamentsArea-img {
    width: 298px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .TournamentsArea-img {
    width: 310px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .TournamentsArea-img {
    width: 310px;
  }
}

@media (min-width: 1600px) {
  
  .TournamentsArea-img {
    width: 310px;
  }
}

.TournamentsArea-blob {
  display: flex;
  width: 150px;
  height: 150px;
  background-color: rgba(170, 118, 7, 0.2);
  opacity: 1;
  border-radius: 50%;
  filter: blur(12px);
  position: absolute;
  z-index: 1;
  animation: blob-bounce 28s infinite ease;
}
/* Tournament Image background animation, light */
