/* No Empty Room */
.noEmptyRoom{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 120px;
  overflow: hidden;
  border-radius: .4rem;
  background: var(--guest-content);
  box-shadow: var(--box-shadow-signin);
  color: var(--white-second);
  box-shadow: var(--box-shadow-signin);
  margin: .1rem 1rem;
  position: absolute;
  text-shadow: var(--text-shadow-simple);
}
/* No Empty Room */

@media (max-width: 480px) {
  
  .noEmptyRoom{
    width: 224px;
    height: 86px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .noEmptyRoom{
    width: 232px;
    height: 92px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .noEmptyRoom{
    width: 250px;
    height: 100px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .noEmptyRoom{
    width: 250px;
    height: 100px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .noEmptyRoom{
    width: 280px;
    height: 120px;
  }
}

@media (min-width: 1600px) {
  
  .noEmptyRoom{
    width: 280px;
    height: 120px;
  }
}

/* No Empty Room Loading */
.noEmptyRoom-loading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: hide .98s forwards;
}
/* No Empty Room Loading */

/* No Empty Room text */
.noEmptyRoom h5 {
  font: 600 .82rem 'Raleway', sans-serif;
  opacity: 0;
  color: var(--forth-color);
  text-shadow: var(--text-shadow);
  animation: show 1.2s forwards ;
}
/* No Empty Room text */