@keyframes roomGameNumbersLayerBack {
  
  0% { 
    z-index: 4;
  }

  100% { 
    z-index: -1;
  }

}

@keyframes roomGameNumbersPointerEvent {

  0% { 
    pointer-events: none;
  }
 
  100% { 
    pointer-events: auto;
  }

}


@keyframes roomGameNumbersLightChanger {

  0%, 10%, 100% {
    background: transparent;
  }
  5% {
    background: rgba(119, 112, 187, .112);
  }

}