.WorldCup{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  position: absolute;
}

.WorldCup_loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: .4rem;
  background: var(--settings-color-trans);
  box-shadow: var(--box-shadow-features);
  position: absolute;
  animation: hide 3s forwards, WorldCupCursorNone .9s 2.2s forwards;
  z-index: 3;
}

.WorldCup_map_place{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: show 3.3s forwards;
}

@media (max-width: 480px) {
  
  #WorldCup_map{
    width: calc(100% - 32px);
    min-width: 328px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  #WorldCup_map{
    width: calc(100% - 32px);
    max-width: 618px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  #WorldCup_map{
    width: 618px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  #WorldCup_map{
    width: 618px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  #WorldCup_map{
    width: 618px;
  }
}

@media (min-width: 1600px) {
  
  #WorldCup_map{
    width: 618px;
  }
}
/* World map */