.AllSingleRoomsContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: calc(100% - 80px);
  height: calc(100% - 14px);
  opacity: 0;
  animation: show 5s forwards;
}

.AllSingleRoomsContainer::before{
content: "";
display: flex;
width: calc(100% - 80px);
height: calc(100% - 14px);
position: absolute;
z-index: 3;
animation: allGameRoomsCursorNone .9s 4.2s forwards;
}