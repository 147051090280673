.GameWatchingExplanation{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: 50%;
  box-shadow: var(--box-shadow-signin);
  opacity: 1;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 2;
}

.GameWatchingExplanationTextContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 260px;
  border-radius: .8rem;
  box-shadow: var(--box-shadow-signin);
  background: var(--forth-color);
  transform: scale(0, 0);
  cursor: default;
  position: absolute;
  top: 4px;
  right: 40px;
}

.GameWatchingExplanationText{
  color: var(--main-hex);
  font: bold .8rem 'Raleway', sans-serif;
  padding: 1.2rem 1rem;
  text-align: center;
  text-shadow: var(--text-shadow-simple);
}

.GameWatchingExplanationIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.GameWatchingExplanation:hover .GameWatchingExplanationTextContainer{
  transition: .24s all ease-in-out;
  transform: scale(1, 1);
}

.GameWatchingExplanation:not(:hover) .GameWatchingExplanationTextContainer{
  transition: .14s 1s all ease-in-out;
  transform: scale(0, 0);
}

.GameWatchingExplanationIconImg{
  width: 40px;
}