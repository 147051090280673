@keyframes sizeForCursorSmall {

  0% { 
    width: 460px;
    height: 200px;
    z-index: 3;
  }
  90% { 
    width: 460px;
    height: 200px;
    z-index: 3;
  }
  100% { 
    width: 200px;
    height: 150px;
    z-index: 0;
  }

}

@keyframes AvatarPointsMoney {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
  
}