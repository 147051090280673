.RoomDigits{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 112px;
  opacity: 0;
  border-radius: 50%;
  background: rgb(105, 103, 224);
  border: 8px outset black;
  position: absolute;
  z-index: 1;
  animation: show .04s forwards;
}

.RoomDigits::after{
  content: attr(data);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  font: bold 3.8rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-simple);
}

.RoomDigitsGuessed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 116px;
  opacity: 0;
  border-radius: 50%;
  background: rgb(72, 129, 49);
  border: 4px solid rgb(72, 129, 49);
  position: absolute;
  z-index: 1;
  animation: show .04s forwards;
}

.RoomDigitsGuessed::after{
  content: "Match!";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  font: bold 1.84rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-simple);
}

