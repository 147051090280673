.MileStones{
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--box-shadow-feature-items);
  background: var(--forth-nav);
  border-radius: 1.2rem;
  position: relative;
}

.MileStones h4{
  color: var(--main-wave);
  padding: .2rem;
  text-shadow: var(--text-shadow-extra);
  cursor: default;
}

#firstMile:hover #firstMilestone, #secondMile:hover #secondMilestone, #thirdMile:hover #thirdMilestone{
  transition: all .6s;
  opacity: 1;
}

#firstMilestone h4, #secondMilestone h4, #thirdMilestone h4{
  font: bold .68rem 'Raleway', sans-serif;
}

.firstMilestoneWinner, .secondMilestoneWinner, .thirdMilestoneWinner{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 164px;
  height: 36px;
  background: rgba(0, 0, 0, 0.98);
  box-shadow: var(--box-shadow-signin);
  opacity: 0;
  border-bottom: 2px outset var(--settings-color);
  border-radius: .4rem;
  position: absolute;
}

@media (max-width: 480px) {
  
  .MileStones{
    width: 36px;
    height: 36px;
  }

  .MileStones h4{
    font: 600 .8rem 'Raleway', sans-serif;
  }

  .firstMilestoneWinner, .secondMilestoneWinner, .thirdMilestoneWinner{
    left: -58px;
    bottom: -42px;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .MileStones{
    width: 36px;
    height: 36px;
  }

  .MileStones h4{
    font: 600 .8rem 'Raleway', sans-serif;
  }

  .firstMilestoneWinner, .secondMilestoneWinner, .thirdMilestoneWinner{
    left: -58px;
    bottom: -42px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .MileStones{
    width: 50px;
    height: 50px;
  }

  .MileStones h4{
    font: 600 .8rem 'Raleway', sans-serif;
  }

  .firstMilestoneWinner, .secondMilestoneWinner, .thirdMilestoneWinner{
    left: -32px;
    top: -42px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .MileStones{
    width: 50px;
    height: 50px;
  }

  .MileStones h4{
    font: 600 .8rem 'Raleway', sans-serif;
  }

  .firstMilestoneWinner, .secondMilestoneWinner, .thirdMilestoneWinner{
    left: -32px;
    top: -42px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .MileStones{
    width: 50px;
    height: 50px;
  }

  .MileStones h4{
    font: 600 .8rem 'Raleway', sans-serif;
  }

  .firstMilestoneWinner, .secondMilestoneWinner, .thirdMilestoneWinner{
    left: -32px;
    top: -42px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .MileStones{
    width: 50px;
    height: 50px;
  }

  .MileStones h4{
    font: 600 .8rem 'Raleway', sans-serif;
  }

  .firstMilestoneWinner, .secondMilestoneWinner, .thirdMilestoneWinner{
    left: -32px;
    top: -42px;
  }
}

@media (min-width: 1600px) {
  
  .MileStones{
    width: 50px;
    height: 50px;
  }

  .MileStones h4{
    font: 600 .8rem 'Raleway', sans-serif;
  }

  .firstMilestoneWinner, .secondMilestoneWinner, .thirdMilestoneWinner{
    left: -32px;
    top: -42px;
  }
}
