@keyframes GameWatchingLimitations-dark {

  0%{
    opacity: 1;
  }
  100%{
    opacity: .52;
  }

}
