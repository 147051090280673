.GameOverExplanation{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--main-wave);
  border-radius: 50%;
  box-shadow: var(--box-shadow-signin);
  opacity: 1;
  position: absolute;
  z-index: 2;
}

@media (max-width: 480px) {
 
  .GameOverExplanation{
    left: 0;
    top: -22px;
  }
}

@media (min-width: 481px){
  
  .GameOverExplanation{
    right: -58px;
    top: -10px;
  }
}

.GameOverExplanationTextContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  transform: scale(0, 0);
  background: var(--black);
  border-radius: .4rem;
  cursor: default;
  position: absolute;
}

@media (max-width: 480px) {
 
  .GameOverExplanationTextContainer{
    width: 350px;
    bottom: 48px;
    left: 0px;
  }
}

@media (min-width: 481px){
  
  .GameOverExplanationTextContainer{
    width: 420px;
    bottom: 48px;
    right: 28px;
  }
}

.GameOverExplanationText{
  color: var(--main-hex);
  font: 600 .8rem 'Raleway', sans-serif;
  padding: 1.4rem;
  text-align: center;
  text-shadow: var(--text-shadow-simple);
  position: absolute;
  top: 12px;
}

.GameOverExplanationIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.GameOverExplanation:hover .GameOverExplanationTextContainer{
  transition: .24s all ease-in-out;
  transform: scale(1, 1);
}

.GameOverExplanation:not(:hover) .GameOverExplanationTextContainer{
  transition: .14s 4s all ease-in-out;
  transform: scale(0, 0);
}

.GameOverExplanationIconImg{
  width: 40px;
}