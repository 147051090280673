@keyframes NavHeaderBigger {

  0% {
    padding: .34rem 1.2rem;
  }

  50% {
    padding: .34rem 2rem;
  }

  100% {
    padding: .34rem 1.2rem;
  }
  
}