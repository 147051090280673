.SupportOasis{
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 5s;
}

@media (max-width: 480px) {
  
  .SupportOasis{
    flex-direction: row;
    width: 350px;
    height: 80px;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .SupportOasis{
    flex-direction: row;
    width: 420px;
    height: 80px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .SupportOasis{
    flex-direction: column;
    width: 80px;
    height: 280px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .SupportOasis{
    flex-direction: column;
    width: 80px;
    height: 280px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .SupportOasis{
    flex-direction: column;
    width: 80px;
    height: 280px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .SupportOasis{
    flex-direction: column;
    width: 80px;
    height: 280px;
  }
}

@media (min-width: 1600px) {
  
  .SupportOasis{
    flex-direction: column;
    width: 80px;
    height: 280px;
  }
}

.SupportOasis::before{
  content: attr(data-oaishelp);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 40px;
  opacity: .18;
  color: var(--white-first);
  position: absolute;
}

.SupportOasis::after{
  content: "Matched: " attr(data-oasismatched);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 36px;
  opacity: .18;
  color: var(--main-color-first);
  position: absolute;
}

@media (max-width: 480px) {
  
  .SupportOasis::before{
    font: 600 1.14rem 'Raleway', sans-serif;
    top: -362px;
    right: 8px;
  }

  .SupportOasis::after{
    font: 600 .8rem 'Raleway', sans-serif;
    top: -38px;
    right: -10px;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .SupportOasis::before{
    font: 600 1.2rem 'Raleway', sans-serif;
    top: -412px;
    right: 8px;
  }

  .SupportOasis::after{
    font: 600 .88rem 'Raleway', sans-serif;
    top: -42px;
    right: -8px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .SupportOasis::before{
    font: 600 1.3rem 'Raleway', sans-serif;
    top: -58px;
    left: -132px;
  }

  .SupportOasis::after{
    font: 600 .92rem 'Raleway', sans-serif;
    bottom: -74px;
    left: -182px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .SupportOasis::before{
    font: 600 1.3rem 'Raleway', sans-serif;
    top: -58px;
    left: -148px;
  }

  .SupportOasis::after{
    font: 600 .92rem 'Raleway', sans-serif;
    bottom: -74px;
    left: -198px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .SupportOasis::before{
    font: 600 1.3rem 'Raleway', sans-serif;
    top: -58px;
    left: -154px;
  }

  .SupportOasis::after{
    font: 600 .92rem 'Raleway', sans-serif;
    bottom: -74px;
    left: -204px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .SupportOasis::before{
    font: 600 1.3rem 'Raleway', sans-serif;
    top: -58px;
    left: -154px;
  }

  .SupportOasis::after{
    font: 600 .92rem 'Raleway', sans-serif;
    bottom: -74px;
    left: -204px;
  }
}

@media (min-width: 1600px) {
  
  .SupportOasis::before{
    font: 600 1.3rem 'Raleway', sans-serif;
    top: -58px;
    left: -154px;
  }

  .SupportOasis::after{
    font: 600 .92rem 'Raleway', sans-serif;
    bottom: -74px;
    left: -204px;
  }
}

/* Oasis Buttons */
.SupportOasisBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--main-wave);
  border-radius: .2rem;
  border: 2px solid var(--main-wave);
  text-transform: uppercase;
  transition: .5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
 }

 @media (max-width: 480px) {
  
  .SupportOasisBtn{
    width: 110px;
    height: 32px;
    font: bold .70rem 'Raleway', sans-serif;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .SupportOasisBtn{
    width: 112px;
    height: 34px;
    font: bold .70rem 'Raleway', sans-serif;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .SupportOasisBtn{
    width: 120px;
    height: 38px;
    font: bold .72rem 'Raleway', sans-serif;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .SupportOasisBtn{
    width: 120px;
    height: 38px;
    font: bold .72rem 'Raleway', sans-serif;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .SupportOasisBtn{
    width: 120px;
    height: 38px;
    font: bold .72rem 'Raleway', sans-serif;
  }
}

@media (min-width: 1600px) {
  
  .SupportOasisBtn{
    width: 120px;
    height: 38px;
    font: bold .72rem 'Raleway', sans-serif;
  }
}
 
.SupportOasisBtn::after, .SupportOasisBtn::before {
  content: '';
  display: flex;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  transition: .5s ease-out;
  background-color: var(--main-wave);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
}
 
.SupportOasisBtn::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}
 
.SupportOasisBtn:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}
 
.SupportOasisBtn:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
}
 
.SupportOasisBtn:hover {
  color: var(--forth-nav)
}
 
.SupportOasisBtn:active {
  filter: brightness(.7);
  transform: scale(.98);
}

.OasisBtnActive { 
  background-color: var(--main-color);
  color: var(--forth-nav);
  border: 2px solid var(--forth-nav);
}
/* Oasis Buttons */

#firstOasis, #secondOasis, #thirdOasis, #forthOasis, #fifthOasis{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 40px;
  background: rgba(0, 0, 0, 0.98);
  box-shadow: var(--box-shadow-signin);
  border-bottom: 2px solid var(--secondary-color-first);
  border-radius: .4rem;
  opacity: 0;
  position: absolute;
}

@media (max-width: 480px) {
  
  #firstOasis, #secondOasis, #thirdOasis, #forthOasis, #fifthOasis{
    top: -42px;
    right: -36px;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  #firstOasis, #secondOasis, #thirdOasis, #forthOasis, #fifthOasis{
    top: -42px;
    right: -36px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  #firstOasis, #secondOasis, #thirdOasis, #forthOasis, #fifthOasis{
    top: -42px;
    right: -6px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  #firstOasis, #secondOasis, #thirdOasis, #forthOasis, #fifthOasis{
    top: -42px;
    right: -6px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  #firstOasis, #secondOasis, #thirdOasis, #forthOasis, #fifthOasis{
    top: -42px;
    right: -6px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  #firstOasis, #secondOasis, #thirdOasis, #forthOasis, #fifthOasis{
    top: -42px;
    right: -6px;
  }
}

@media (min-width: 1600px) {

  #firstOasis, #secondOasis, #thirdOasis, #forthOasis, #fifthOasis{
    top: -42px;
    right: -6px;
  }
}

#firstOasis h4, #secondOasis h4, #thirdOasis h4, #forthOasis h4, #fifthOasis h4{
  font: var(--logged-userName);
  color: var(--main-hex);
  text-shadow: var(--text-shadow-extra);
  cursor: default;
}

#firstOasisBtn::after, #secondOasisBtn::after, #thirdOasisBtn::after, #forthOasisBtn::after, #fifthOasisBtn::after{
  content: attr(data);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font: 600 .75rem 'Raleway', sans-serif;
  background: var(--forth-nav);
  opacity: .35;
  color: var(--secondary-color);
  position: absolute;
}

@media (max-width: 480px) {
  
  #firstOasisBtn::after, #secondOasisBtn::after, #thirdOasisBtn::after, #forthOasisBtn::after, #fifthOasisBtn::after{
    left: 50%;
    top: 50%;
    transform: translate(-50%, 42%);
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  #firstOasisBtn::after, #secondOasisBtn::after, #thirdOasisBtn::after, #forthOasisBtn::after, #fifthOasisBtn::after{
    left: 50%;
    top: 50%;
    transform: translate(-50%, 42%);
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  #firstOasisBtn::after, #secondOasisBtn::after, #thirdOasisBtn::after, #forthOasisBtn::after, #fifthOasisBtn::after{
    left: -30px;
    top: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  #firstOasisBtn::after, #secondOasisBtn::after, #thirdOasisBtn::after, #forthOasisBtn::after, #fifthOasisBtn::after{
    left: -30px;
    top: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  #firstOasisBtn::after, #secondOasisBtn::after, #thirdOasisBtn::after, #forthOasisBtn::after, #fifthOasisBtn::after{
    left: -30px;
    top: 22px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  #firstOasisBtn::after, #secondOasisBtn::after, #thirdOasisBtn::after, #forthOasisBtn::after, #fifthOasisBtn::after{
    left: -30px;
    top: 22px;
  }
}

@media (min-width: 1600px) {
 
  #firstOasisBtn::after, #secondOasisBtn::after, #thirdOasisBtn::after, #forthOasisBtn::after, #fifthOasisBtn::after{
    left: -30px;
    top: 22px;
  }
}

#firstOasisBtn:hover #firstOasis{
  opacity: 1;
  transition: all 3.2s;
}

#secondOasisBtn:hover #secondOasis{
  opacity: 1;
  transition: all 3.2s;
}

#thirdOasisBtn:hover #thirdOasis{
  opacity: 1;
  transition: all 3.2s;
}

#forthOasisBtn:hover #forthOasis{
  opacity: 1;
  transition: all 3.2s;
}

#fifthOasisBtn:hover #fifthOasis{
  opacity: 1;
  transition: all 3.2s;
}