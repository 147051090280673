.PlayOrWatchExplanation{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: 50%;
  box-shadow: var(--box-shadow-signin);
  opacity: .8;
  position: absolute;
  right: 8px;
  top: 18px;
  z-index: 1;
}

.PlayOrWatchExplanationTextContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 332px;
  height: 72px;
  transform: scale(0, 0);
  cursor: default;
  background: var(--grey-simple);
  box-shadow: var(--box-shadow-signin);
  border-radius: .3rem;
  position: absolute;
  top: -104px;
  right: 0px;
}

.PlayOrWatchExplanationText{
  color: var(--main-nav);
  font: var(--signin-form-fifth);
  padding: .2rem .8rem;
  text-align: center;
  text-shadow: var(--text-shadow-simple);
  position: absolute;
  bottom: 2px;
}

.PlayOrWatchExplanationIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.PlayOrWatchExplanation:hover .PlayOrWatchExplanationTextContainer{
  transition: .24s all ease-in-out;
  transform: scale(1, 1);
}

.PlayOrWatchExplanation:not(:hover) .PlayOrWatchExplanationTextContainer{
  transition: .14s 4s all ease-in-out;
  transform: scale(0, 0);
}

.PlayOrWatchExplanationIconImg{
  width: 40px;
}