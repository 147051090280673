/* player game rooms area // rooms big container */
.AllMultiRoomsContainerArea{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 12px);
  height: calc(100% - 114px);
  border-radius: .2rem;
  background: rgba(148, 57, 5, 0.24);
  box-shadow: var(--box-shadow-signin);
  opacity: 0;
  position: absolute;
  animation: show 4s forwards;
}
/* player game rooms area // rooms big container */

/* Game Room 2nd loading conatiner*/
.AllMultiRoomsContainerArea-loading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 12px);
  height: calc(100% - 114px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: hide 4.2s forwards;
}
/* Game Room 2nd loading conatiner*/