.NavPage{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  background: rgba(0, 0, 0, .56);
  box-shadow: var(--box-shadow-signin);
  border-radius: 10px;
  cursor: default;
  position: relative;
  margin-top: 2rem;
  margin-bottom: .5rem;
  z-index: 4;
}

@media (max-width: 480px) {
  
  .NavPage{
    min-width: 350px;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .NavPage{
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .NavPage{
    width: 100%;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .NavPage{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .NavPage{
    width: 800px;
  }
}

@media (min-width: 1600px) {
  
  .NavPage{
    width: 800px;
  }
}

.ContactUs{
  background-image: url('../../../Images/bluishBgr.jpg');
}

.Nav_hide-hex{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  animation: hide 1.9s forwards;
}

.NavHeaderPlace{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 48px;
  background: var(--grey-first);
  opacity: 0;
  box-shadow: var(--box-shadow-signin);
  clip-path: var(--hexagon-secondary);
  position: absolute;
  top: -24px;
  animation: show 2.4s forwards;
}

.NavHeaderPlace h3{
  background: var(--black-light);
  box-shadow: var(--box-shadow-feature-items);
  font: var(--nav-font);
  color: var(--brown);
  padding: .34rem 1.2rem;
  text-shadow: var(--text-shadow);
  border: 1px solid var(--main-nav);
  border-radius: .4rem;
}

.h3_transform{
  animation: NavHeaderBigger 1.4s ease-in-out 2.4s forwards;
}

.NavText{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 12px);
  height: 100%;
  background: var(--grey-light);
  opacity: 0;
  box-shadow: var(--box-shadow-signin);
  border-radius: .3rem;
  padding: .5rem .2rem;
  margin-top: 1.8rem;
  animation: show 2.4s forwards;
}

.NavText h4{
  color: var(--main-color-third);
  text-shadow: var(--text-shadow-simple);
  font: bold .98rem 'Raleway', sans-serif;
}

.NavText p{
  color: var(--white-first);
  text-shadow: var(--text-shadow-simple);
  text-align: justify;
  font: normal .88rem 'Raleway', sans-serif;
}

@media (max-width: 767px) {
  
  .NavText p{
    font: normal .78rem 'Raleway', sans-serif;
  }
}

@media (min-width: 768px) {
  
  .NavText p{
    font: normal .88rem 'Raleway', sans-serif;
  }
}

.NavText h5{
  display: block;
  color: var(--brown);
  font: bold .92rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-simple);
  text-align: justify;
}

.NavText-socials:link{
  color: var(--main-hex);
  text-decoration: none;
}

.NavText-socials:hover{
  opacity: .8;
}

.NavText-socials:visited{
  color: var(--main-hex);
}

.NavText-socials:active{
  color: var(--main-hex);
}

.NavText-modes {
  display: flex;
  flex-direction: column;
  height: 280px;
  background: rgba(0, 0, 0, .18);
  padding: .8rem;
  border-radius: .4rem;
  overflow: scroll;
  overflow-x: hidden;
}

.NavText-modes::-webkit-scrollbar{
  width: .9rem;
}

.NavText-modes::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 .8rem var(--white-first);
  border-radius: .4rem;
}

.NavText-modes::-webkit-scrollbar-thumb {
  border-radius: .8rem;
  -webkit-box-shadow: inset 0 0 6px var(--main-nav); 
}

.NavText-modes-text-emph{
  text-align: left;
}

.NavText-modes-single, .NavText-modes-multi, .NavText-modes-tour, .NavText-modes-login, .NavText-modes-create, .NavText-modes-origins, .NavText-modes-mission, .NavText-modes-competition, .NavText-modes-rewards, .NavText-modes-community{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: rgba(0, 0, 0, .08);
  margin: .6rem 0;
}

.NavText-modes-emph{
  font-weight: 500;
  color: var(--main-nav);
}

.NavText h6{
  color: var(--main-wave);
  margin-right: .3rem;
  font: bold .92rem 'Raleway', sans-serif;
}

.NavText-modes-click{
  color: var(--secondary-color);
  cursor: pointer;
}

.NavText-modes-click:hover{
  color: var(--settings-color);
}

/* Game Settings - The switch */
.NavFeatures{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 12px);
  height: 100%;
  background: var(--grey-light);
  opacity: 0;
  box-shadow: var(--box-shadow-signin);
  border-radius: .3rem;
  padding: .5rem .2rem;
  margin-top: 1.8rem;
  animation: show 2.4s forwards;
}

.NavFeatures h4{
  color: var(--main-color-third);
  text-shadow: var(--text-shadow-simple);
  font: bold .98rem 'Raleway', sans-serif;
}

.NavFeatures h5{
  display: block;
  color: var(--brown);
  font: bold .92rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-simple);
  text-align: justify;
}

.NavFeatures-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 280px;
  border-radius: .4rem;
  background: var(--grey-light-trans);
  box-shadow: var(--box-shadow-signin);
}

.GameSettings_items{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 50px;
  margin-top: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid var(--grey-light);
  border-radius: .5rem;
  box-shadow: var(--box-shadow-feature-items);
}

.GameSettings_items h4{
  color: var(--main-nav);
  margin-right: 3rem;
}

.items_switch {
  display: inline-block;
  width: 80px;
  height: 28px;
  position: relative;
}

.items_slider {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--main-color-second);
  color: black;
  font: var(--avatar-table);
  border-radius: 30px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: .2s;
}

.items_slider:before {
  content: "Off";
  display: grid;
  align-content: center;
  justify-content: center;
  height: 90%;
  width: 48%;
  left: 2%;
  border-radius: 20px;
  background-color: var(--main-color-third);
  color: var(--off-red);
  box-shadow: var(--box-shadow-nav-set);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: .4s;
  position: absolute;
}

.items_slider:after {
  content: "";
  position: absolute;
  top: -7px;
  left: -7px;
  right: -7px;
  bottom: -7px;
  border-radius: 1.71em;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.1));
  z-index: -1;
}

.items_switch input:checked + .items_slider {
  background-color: var(--brown);
  color: var(--white-first);
}

.items_switch input:checked + .items_slider:before {
  content: "On";
  transform: translateX(100%);
  color: var(--on-green);
}

.items_switch input {
  display: none;
}
/* Game Settings - The switch */