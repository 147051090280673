.forgetPassword{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  min-width: 300px;
  height: 290px;
  min-height: 290px;
  background: var(--grey);  
  opacity: 1;
  padding: 40px;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: relative;
  z-index: 5;
  animation: show .4s forwards, forgetPasswordEvent 1.6s forwards, forgetPasswordShow 1.1s forwards;
}

.forgetPassword_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 40px;
  position: relative;
}

/* Forget Password Completed Message */
.ForgetPasswordCompletedMessage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 60px;
  opacity: 0;
  background: var(--grey); 
  border: 6px solid var(--main-wave);
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  top: 60px;
  left: 50%;
  animation: show .8s forwards, hide 3.2s forwards;
  transform: translate(-50%, -40%);
  z-index: 5;
}

.ForgetPasswordCompletedMessage h2 {
  font: var(--avatar-table);
  color: var(--white-second);
  padding: 0.5rem 1rem;
  text-shadow: var(--text-shadow-simple);
}
/* Forget Password Completed Message */