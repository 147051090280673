@keyframes WaitingForOtherPlayersBgr {

  0%{
    background: rgba(2, 42, 68, 0.7);
  }
  50%{
    background: rgba(4, 36, 58, 0.7);
  }
  100%{
    background: rgba(6, 24, 36, 0.7);
  }

}

@keyframes waitingClockSpin {

  from {
    transform:rotate(0deg);
    transform-origin: 50% 50%;
  }
  to {
    transform:rotate(360deg);
    transform-origin: 50% 50%;
  }
  
}