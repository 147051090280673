.AvatarEdittingPlace{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 200px;
  border-radius: .2rem;
  background: var(--grey-simple);  
  opacity: 1;
  box-shadow: var(--box-shadow-menu);
  position: relative;
}

@media (max-width: 480px) {
  
  .AvatarEdittingPlace{
    width: 100%;
    min-width: 320px;
    height: 102px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
 
  .AvatarEdittingPlace{
    width: 412px;
    height: 112px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .AvatarEdittingPlace{
    width: 412px;
    height: 112px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .AvatarEdittingPlace{
    width: 412px;
    height: 112px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .AvatarEdittingPlace{
    width: 468px;
    height: 112px;
  }
}

@media (min-width: 1600px) {
  
  .AvatarEdittingPlace{
    width: 500px;
    height: 200px;
  }
}

.AvatarEdittingPlace-container {
	width: 100%;
	height: 100%;
	background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3) 35%, rgba(0, 0, 0, 0.7));
  cursor: default;
}

.AvatarEdittingPlace-container h2 {
  width: 100%;
	height: 32px;
	font-size: 2rem;
  font-weight: 500;
  text-align: center;
	color: transparent;
	position: absolute;
	top: 50%;
	animation: AvatarEdittingPlace-blurFadeInOut 3s ease-in backwards;
}

.AvatarEdittingPlace-container h2.AvatarEdittingPlace-frame-1 {
	animation-delay: 0s;
}

.AvatarEdittingPlace-container h2.AvatarEdittingPlace-frame-2 {
	animation-delay: 3s;
}

.AvatarEdittingPlace-container h2.AvatarEdittingPlace-frame-3 {
	animation-delay: 6s;
}

.AvatarEdittingPlace-container h2.AvatarEdittingPlace-frame-4 {
	font-size: 48px;
	animation-delay: 9s;
}

.AvatarEdittingPlace-container h2.AvatarEdittingPlace-frame-5 {
	color: transparent;
	text-shadow: 0px 0px 1px var(--main-wave);
  animation: none;
}

.AvatarEdittingPlace-container h2.AvatarEdittingPlace-frame-5 span {
	color: transparent;
	text-shadow: 0px 0px 1px var(--main-wave);
  animation: AvatarEdittingPlace-blurFadeIn 3s ease-in 12s backwards;
}

.AvatarEdittingPlace-container h2.AvatarEdittingPlace-frame-5 span:nth-child(2) {
	animation-delay: 13s;
}

.AvatarEdittingPlace-container h2.AvatarEdittingPlace-frame-5 span:nth-child(3) {
	animation-delay: 14s;
}

.AvatarEditCard{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: .2rem;
  background: var(--grey-simple);
  box-shadow: var(--box-shadow-menu);
  opacity: 1;
  position: relative;
}

@media (max-width: 480px) {
  
  .AvatarEditCard{
    width: 100%;
    min-width: 320px;
    height: 168px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .AvatarEditCard{
    width: 412px;
    height: 184px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .AvatarEditCard{
    width: 412px;
    height: 184px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .AvatarEditCard{
    width: 412px;
    height: 184px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .AvatarEditCard{
    width: 432px;
    height: 184px;
  }
}

@media (min-width: 1600px) {
  
  .AvatarEditCard{
    width: 500px;
    height: 230px;
  }
}

.AvatarEditCard::before{
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--third-color);
  opacity: 0;
  border: .3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: show 2s forwards, sizeForCursorNone 3s forwards;
}

.AvatarEditCard-loading-circle-place{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  animation: hide 1.7s forwards;
}

.radio-input-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  animation: show 2s forwards;
  position: relative;
  z-index: 2;
}

.darkOpacity{
  border-radius: .4rem;
  background: rgb(83, 70, 70);
  position: relative;
  z-index: 4;
}

.AvatarEditCard .label {
  display: flex;
  align-items: center;
  border-radius: 6rem;
  padding: .8rem 1rem;
  margin: .3rem 0;
  cursor: pointer;
  transition: .3s;
}

.AvatarEditCard .label:hover,
.AvatarEditCard .label:focus-within,
.AvatarEditCard .label:active {
  background: rgba(204, 204, 204, 0.14);
}

.AvatarEditCard .radio-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.AvatarEditCard .radio-design {
  width: 22px;
  height: 22px;
  border-radius: 100px;
  background: var(--guest-content);
  position: relative;
}

.AvatarEditCard .radio-design::before {
  content: '';
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: var(--white-second);
  transform: scale(1.1);
  transition: .3s;
}

.AvatarEditCard .radio-input:checked+.radio-design::before {
  transform: scale(0);
}

.AvatarEditCard .label-text {
  color: rgb(153, 153, 153);
  margin-left: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  /* font-size: 18px;
  font-weight: 900; */

  font: var(--editFeatures-font);
  transition: .3s;
}

.AvatarEditCard .radio-input:checked~.label-text {
  color: var(--main-wave);
  justify-content: center;
  align-items: center;
}

.AvatarEditCard-deleteAcc-place{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 10px;
  right: 10px;
}

.AvatarEditCard-deleteAcc-place-img{
  border: 2px solid var(--grey-light);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  opacity: 0;
  animation: show 2s forwards;
}


.AvatarEditCard-deleteAcc-place-img:hover{
  box-shadow: var(--box-shadow_bonuses);
  border: 4px solid red;
}
