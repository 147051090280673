.RestartGame{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% / 1.3);
  min-width: 350px;
  height: 212px;
  background: rgba(24, 2, 83, 0.2);
  opacity: 0;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  cursor: default;
  z-index: 2;
  animation: show .3s forwards;
}

.RestartGame-cardContainer {
  display: flex;
  width: calc(100% / 4.1);
  height: 64px;
  outline: 6px solid var(--white-second);
  border-radius: 4px;
  line-height: 120%;
  padding: 1rem;
  margin: .8rem;
  color: var(--black-light);
  background: var(--main-wave);
  background-blend-mode: multiply;
  background: linear-gradient(to top, var(--main-wave), var(--main-hex));
  transition: background-color 1s ease-in-out;
  overflow: hidden;
  position: relative;
}

.RestartGame-card {
  bottom: 10px;
  left: 0;
  position: absolute;
  width: 100%;
  text-align: center;
  transition: transform 1s cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

.RestartGame-cardTitle {
  font: bold .8rem 'Raleway', sans-serif; ;
}

.RestartGame-cardTitle::after {
  content: "";
  display: block;
  width: 50%;
  border-radius: 50%;
  height: 2px;
  margin: 2px auto;
  background-color: var(--black-light);
}


.RestartGame-Cardtext {
  font: 600 .65rem 'Raleway', sans-serif;
}



