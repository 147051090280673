@keyframes RoomGameNumbers-gameStatus-hide {

  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(1, 1);
    opacity: 1;
  }
  95% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(0, 0);
    opacity: 0;
  }
}
