 /* Create Rooms area */
.CreateRoomContainer-bgr{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(31, 0, 0, 0.34);
  position: relative;
}

@media (max-width: 480px) {
  
  .CreateRoomContainer-bgr{
    width: 100%;
    min-width: 350px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  .CreateRoomContainer-bgr{
    width: 480px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .CreateRoomContainer-bgr{
    width: 500px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
 
  .CreateRoomContainer-bgr{
    width: 500px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .CreateRoomContainer-bgr{
    width: 500px;
  }
}

@media (min-width: 1600px) {
  
  .CreateRoomContainer-bgr{
    width: 500px;
  }
}

 .CreateRoomContainer{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  gap: 2px;
  flex-wrap: wrap;
  overflow: hidden;
  position: absolute;
  animation: show .4s forwards, createRoom-Bgr 1.2s forwards;
  z-index: 4;
}

.CreateRoomContainer::before {
  content: '';
  width: 100%;
  height: 100%;
  background: var(--signin-background);
  opacity: 0;
  position: absolute;
  animation: show 1.4s forwards, createRoom-Bgr-move 12s linear infinite;
}

.CreateRoomContainer_infinityPlace{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  position: absolute;
  z-index: 1;
  animation: hide 1s forwards;
}

.CreateRoomContainer > span {
  display: flex;
  width: calc(6.25vw - 2px);
  height: calc(6.25vw - 2px);
  background: var(--black-light);
  transition: 1.5s;
  position: relative;
}

@media (max-width: 480px) {
  
  .CreateRoomContainer > span {
    width: calc(22vw - 2px);
    height: calc(22vw - 2px);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .CreateRoomContainer > span {
    width: calc(16.8vw - 2px);
    height: calc(16.8vw - 2px);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .CreateRoomContainer > span {
    width: calc(11vw - 2px);
    height: calc(11vw - 2px);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .CreateRoomContainer > span {
    width: calc(8.5vw - 2px);
    height: calc(8.5vw - 2px);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
 
  .CreateRoomContainer > span {
    width: calc(7vw - 2px);
    height: calc(7vw - 2px);
  }
}

@media (min-width: 1600px) {
 
  .CreateRoomContainer > span {
    width: calc(6.25vw - 2px);
    height: calc(6.25vw - 2px);
  }
}

.CreateRoomContainer > span:hover {
  background: var(--brown-second);
  transition: 0s;
  position: relative;
  
}
/* Create Rooms area */