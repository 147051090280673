.LockedRoom{
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 36px);
  height: calc(100% / 2);
  background-color: rgba(21, 23, 22, .5);
  border-radius: 20px;
  box-shadow: var(--box-shadow-signin);
  cursor: default;
  position: relative;
  z-index: 1;
}

.LockedRoom-lock{
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 274px;
  height: 40px;
  border-radius: .4rem;
  position: absolute;
  top: 8px;
}

.LockedRoom-lock-logo{
  height: 38px;
}

.LockedRoom-passCodeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: calc(100% - 42px);
  height: 100px;
  border-radius: .4rem;
  position: absolute;
  bottom: 12px;
}

.LockedRoom-passCode{
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: calc(100% - 8px);
  height: 50px;
  margin-top: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid var(--grey-light);
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow-feature-items);
  position: relative;
}

.LockedRoom-passCode h3{
  font: var(--signin-form-fifth);
  color: var(--white-first);
  position: absolute;
  top: -26px;
}

.LockedRoom-passCode_input{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 24px;
  font-size: 1.2rem;
  background: transparent;
  color: var(--white-first);
  cursor: default;
  position: absolute;
  z-index: 5;
}