.UserProfile{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background: rgba(78, 3, 177, 0.151); 
  opacity: 0; 
  border-radius: .3rem;
  padding: 2rem 2rem .2rem 2rem;
  box-shadow: var(--box-shadow-signin);
  position: relative;
  transition: all 2s;
  z-index: 3;
  animation: show .12s forwards;
}

/* When clicked Logout or delete accnt btns */
.UserProfile-logout{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background: rgba(5, 1, 27, 0.82); 
  opacity: 0; 
  border-radius: .3rem;
  padding: 2rem 2rem .2rem 2rem;
  box-shadow: var(--box-shadow-signin);
  position: relative;
  transition: all 2s;
  z-index: 3;
  animation: show .12s forwards;
}

@media (max-width: 480px) {
  
  .UserProfile, .UserProfile-logout{
    min-width: 350px;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  .UserProfile, .UserProfile-logout{
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .UserProfile, .UserProfile-logout{
    width: 100%;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .UserProfile, .UserProfile-logout{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
 
  .UserProfile, .UserProfile-logout{
    width: 800px;
  }
}

@media (min-width: 1600px) {

  .UserProfile, .UserProfile-logout{
    width: 800px;
  }
}
