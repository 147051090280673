.InRoomSingle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-shadow: var(--box-shadow-signin);
  background: var(--grey-first);
  border-radius: .4rem;
  background: rgba(255, 0, 0, .05);
  position: absolute;
  z-index: 5;
}

.InRoomSingle-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.InRoomSingle-issues{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 300px;
  border-radius: .4rem;
  background: var(--grey-first);
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  z-index: 6;
}

.InRoomSingle-issues-text{
  font: var(--redirect-font);
  color: var(--white-first);
  text-shadow: var(--text-shadow-simple);
}

.InRoomSingle-issuesNoConnetcion{
  width: 280px;
}