.AvatarBonusDollarBag{
  display: flex;
  width: 128px;
  height: 128px;
  border: 4px solid transparent;
  border-radius: 50%;
  opacity: 0;
  position: relative;
  z-index: 2;
  animation: show 2s forwards;
}

@media (max-width: 991px) {
  
  .AvatarBonusDollarBag{
    display: none;
    width: 72px;
    height: 40px;
    border: 2px solid transparent;
  }
}

.AvatarBonusDollarBag::before{
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 112px;
  background: var(--secondary-color-third);
  opacity: .3;
  border: 4px solid transparent;
  border-radius: 50%;
  position: absolute;
  transition: all .2s;
  z-index: 2;
}

@media (max-width: 991px) {
  
  .AvatarBonusDollarBag::before{
    content: "";
    opacity: 1;
    width: 72px;
    height: 40px;
    border-radius: .2rem;
    background: var(--secondary-color-third);
  }
}

.AvatarBonusDollarBag:hover::before{
  content: "Add Money";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 112px;
  color: var(--main-color);
  background: var(--forth-color);
  opacity: .94;
  border: 4px solid var(--main-color-first);
  border-radius: 50%;
  font: var(--signin-form-fifth);
  position: absolute;
  cursor: pointer;
  z-index: 2;
}

@media (max-width: 991px) {
  
  .AvatarBonusDollarBag:hover::before{
    width: 72px;
    height: 40px;
    border-radius: .2rem;
  }
}

#AvatarBonusDollar{
  width: 120px;
  height: 120px;
  border: 4px solid transparent;
  padding: .2rem;
  border-radius: 50%;
  animation: dollarColors 36s infinite alternate, var(--logo-animation);
  box-shadow: var(--box-shadow-menu);
  position: relative;
}


@media (max-width: 991px) {
  
  #AvatarBonusDollar{
    display: none;
  }
}