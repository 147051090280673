.GameOver{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: .4rem;
  background-image: url('../../../../Images/theWall.png');
  background-repeat: cover;
  position: absolute;
  z-index: 1;
}

.GameOver-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  position: absolute;
  z-index: 3;
  animation: hide 2s forwards;
}

.GameOver-transparency{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: .4rem;
  background: rgba(0, 0, 0, 0.742);
  position: absolute;
  z-index: 2;
}

.GameOver-transparency-logoPlace{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  position: absolute;
  top: 10px;
  animation: goldenLogoBgr 3s alternate ease-out infinite;
}

.GameOver-transparency-logo{
  width: 200px;
}

.GameOver-lost{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 178px;
  background-image: url('../../../../Images/youLost.png');
  opacity: 0;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  z-index: 3;
  animation: show 2s forwards;
}

.GameOver-won{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 178px;
  background-image: url('../../../../Images/youWon.png');
  opacity: 0;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  z-index: 3;
  animation: show 2s forwards;
}

@media (max-width: 480px) {
  /* Styles for small smartphones */
}

@media (min-width: 481px) and (max-width: 767px) {
  /* Styles for larger smartphones */
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Styles for portrait tablets */
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Styles for landscape tablets */
}

@media (min-width: 1200px) and (max-width: 1599px) {
  /* Styles for laptops */
}

@media (min-width: 1600px) {
  
  .GameOver-lost{

  }

  .GameOver-won{

  }
}

/* buttons */
.GameOver-btnsPlace{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: calc(100% / 1.7);
  min-width: 350px;
  height: 100px;
  border: 2px solid var(--forth-nav);
  border-radius: .4rem;
  box-shadow: var(--box-shadow_bonuses);
  opacity: 0;
  position: absolute;
  bottom: 40px;
  z-index: 3;
  animation: show 2s forwards;
}

.GameOver-btnsPlace_btn {
  background: var(--main-wave-trans);
  color: var(--white-second);
  width: 130px;
  height: 2.2rem;
  border: 2px solid transparent;
  border-radius: .3rem;
  text-align: center;
  cursor: pointer;
}

.GameOver-btnsPlace_btn:hover {
  background: var(--brownish);
}

.GameOver-btnsPlace_btn_text {
  pointer-events: none;
}
/* buttons */

.hoorah{
  display: flex;
  width: calc(100% / 2);
  min-width: 350px;
  opacity: 0;
  animation: show 1.4s forwards;
}

.hoorah > .before, .hoorah > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards; }

.hoorah > .after {
  animation-delay: 1.25s, 1.25s, 1.25s;
  animation-duration: 1.25s, 1.25s, 6.25s; 
}

/* Game over timer */
.GameOverTimer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: var(--brownish-trans);
  color: var(--main-wave);
  border-radius: 50%;
  text-shadow: var(--text-shadow);
  box-shadow: var(--box-shadow-signin);
  font: bold .84rem 'Raleway', sans-serif;;
  cursor: default;
}
/* Game over timer */