@keyframes Radar-rotate {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}

@keyframes Radar-doted {

  0% {
    transform: scale(0.95);
    box-shadow: 0 0 10px 5px rgba(24, 211, 33, 0.3),
      0 0 10px 10px rgba(24, 211, 33, 0.3);
  }

  60% {
    transform: scale(0.95);
    box-shadow: 0 0 10px 5px rgba(24, 211, 33, 0.3),
      0 0 10px 10px rgba(24, 211, 33, 0.3);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(24, 211, 33, 0.7), 0 0 0 0 rgba(24, 211, 33, 0.5),
      0 0 0 0 rgba(24, 211, 33, 0.3);
    opacity: 0;
  }
  
}