@keyframes AvatarEdittingPlace-blurFadeInOut {

	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px var(--main-wave);
		transform: scale(1.3);
	}
	20%, 75% {
		opacity: 1;
		text-shadow: 0px 0px 1px var(--main-wave);
		transform: scale(1);
	}
	100% {
		opacity: 0;
		text-shadow: 0px 0px 50px var(--main-wave);
		transform: scale(0);
	}

}

@keyframes sizeForCursorNone {

  0% { 
    width: 100%;
    height: 100%;
    background: var(--third-color);
    z-index: 3;
  }
  90% { 
    width: 100%;
    height: 100%;
    background: var(--third-color);
    z-index: 3;
  }
  100% { 
    width: 0px;
    height: 0px;
    background: transparent;
    z-index: 0;
  }
  
}