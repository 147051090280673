@keyframes goldenLogoBgr {

  0%{
    background-position : 0%;
    box-shadow: 0 0 .8em #7A3E07;
  }
  50%{
    background-position: 60%;
    box-shadow: 0 0 .8em #F3C343;
  }
  100%{
    background-position: 100%;
    box-shadow: 0 0 .8em #C1872B;
  }
}

@keyframes activePlayersTurn {

  0% {
    background-position: 0px;
  }

  100% {
    background-position: 1000px;
  }
}