.forgetPasswordPlace{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--grey-light);
  position: relative;
}

