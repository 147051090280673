@keyframes GameScoreoBgr {

  0%{
    background-position : 0%;
    box-shadow: 0 0 .8em #09077a;
  }
  50%{
    background-position: 60%;
    box-shadow: 0 0 .8em #050449;
  }
  100%{
    background-position: 100%;
    box-shadow: 0 0 .8em #02012c;
  }

}