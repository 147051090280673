.WaitingForOtherPlayers-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: .6rem;
  box-shadow: var(--box-shadow-signin);
  background: rgba(2, 42, 68, 0.7);
  opacity: 0;
  position: absolute;
  animation: show .4s forwards, WaitingForOtherPlayersBgr 3s alternate ease-out infinite;
  z-index: 1;
}

@media (max-width: 480px) {
  
  .WaitingForOtherPlayers-container{
    width: calc(100% / 1.24);
    min-height: 350px;
    height: calc(100% / 1.5);
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  .WaitingForOtherPlayers-container{
    width: calc(100% / 1.24);
    height: calc(100% / 1.5);
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .WaitingForOtherPlayers-container{
    width: calc(100% / 1.8);
    max-width: 400px;
    height: calc(100% / 1.5);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .WaitingForOtherPlayers-container{
    width: calc(100% / 1.8);
    height: calc(100% / 1.5);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .WaitingForOtherPlayers-container{
    width: calc(100% / 2);
    height: calc(100% / 1.8);
  }
}

@media (min-width: 1600px) {
  
  .WaitingForOtherPlayers-container{
    width: calc(100% / 2);
    height: calc(100% / 1.8);
  }
}

/* loader simple  */
.WaitingForOtherPlayers-loading-first{
  display: flex;
  animation: hide 1.2s forwards;
}
/* loader simple  */

/* waiting */
.WaitingForOtherPlayers-text{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  top: -80px;
 }

 @media (max-height: 799px){

  .WaitingForOtherPlayers-text{
    top: -144px;
  }
}

 .WaitingForOtherPlayers-text::after{
  content: "We will start the game once the room is full.";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  width: 100%;
  height: 100%;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  padding: .1rem;
  background: rgba(2, 42, 68, 0.7);
  color: var(--white-first);
  position: absolute;
  font: 600 .86rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-extra);
  animation: WaitingForOtherPlayersBgr 3s alternate ease-out infinite;
 }

 @media (max-width: 480px){

  .WaitingForOtherPlayers-text::after{
    font: 600 .8rem 'Raleway', sans-serif;
  }
}
/* waiting */

/* waiting img */
.WaitingForOtherPlayers-img-container{
  display: flex;
}

.WaitingForOtherPlayers-img {
  width: 232px;
}
/* waiting img */

/* infinite */
.WaitingForOtherPlayers-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 140px;
}
/* infinite */

/* WaitingClock */
.WaitingClock{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -74px;
  right: 10px;
}

.WaitingClock-loader-circle, 
.WaitingClock-loader-pointer, 
.WaitingClock-loader-line {
	fill: none;
	stroke-width: 5px;
	stroke: #7E7E7E;
}

.WaitingClock-loader-center {
	fill: #7E7E7E;
}

.WaitingClock-loader-pointer {
  transform-origin: bottom center;
	animation: waitingClockSpin 4s linear infinite;
}

.WaitingClock-loader-line {
  transform-origin: bottom center;
	animation: waitingClockSpin 60s linear infinite;
}
/* WaitingClock */


