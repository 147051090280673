/* background  */

@keyframes color {

  0% { 
    background: var(--secondary-color); 
  }
  20% { 
    background: var(--green-first); 
  }
  40% { 
    background: var(--green-second);
  }
  60% { 
    background: var(--main-color-second); 
  }
  80% { 
    background: var(--green-first); 
  }
  100% { 
    background: var(--secondary-color); 
  }

}

@keyframes gameRoomsBackgroundColor {

  0% { 
    background: rgba(0, 0, 0, .2);
  }
  100% { 
    background: rgba(76, 49, 21, .4);
  }

}

@keyframes goldenoBgr {

  0%{
    background-position : 0%;
    box-shadow: 0 0 .8em #7A3E07;
  }
  50%{
    background-position: 60%;
    box-shadow: 0 0 .8em #F3C343;
  }
  100%{
    background-position: 100%;
    box-shadow: 0 0 .8em #C1872B;
  }

}

@keyframes goldenoBgrLight{
  0%{
   color: rgb(224, 204, 165);
   box-shadow: rgba(253, 124, 4, 0.5) 0px 54px 55px;
  }
  20%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(224, 111, 5, 0.5) 0px 54px 55px;
  }
  40%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(253, 115, 2, 0.5) 0px 54px 55px;
  }
  60%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(248, 122, 19, 0.5) 0px 54px 55px;
  }
  80%{
    color: rgb(168, 145, 103);
    box-shadow: rgba(241, 91, 4, 0.5) 0px 54px 55px;
  }
  100%{
    color: rgb(204, 197, 184);
    box-shadow: rgba(243, 147, 4, 0.5) 0px 54px 55px;
  }
}

@keyframes signin-Bgr 
{
  0%
  {
    transform: scale(.1)
  }
  100%
  {
    transform: scale(1);
  }
}

@keyframes formShow
{
  0%
  {
    transform: scale(0)
  }
  80%
  {
    transform: scale(0)
  }
  100%
  {
    transform: scale(1);
  }
}

@keyframes signin 
{
  0%
  {
    transform: translateY(-100%);
  }
  100%
  {
    transform: translateY(100%);
  }
}

@keyframes hide {
  0% { 
    opacity: 1; 
  }
  95% { 
    opacity: 1; 
  }
  100% { 
    opacity: 0; 
  }
}

@keyframes hide-partial {
  0% { 
    opacity: 1; 
  }
  95% { 
    opacity: 1; 
  }
  100% { 
    opacity: .044; 
  }
}

@keyframes hide-partial-guest {
  0% { 
    opacity: 1; 
  }
  95% { 
    opacity: 1; 
  }
  100% { 
    opacity: .044; 
  }
}

/* hide by level */
@keyframes PlayerGameRoomsCursorNone {
  0% { 
    z-index: 3;
  }
  100% { 
    z-index: -1;
  }
}
/* hide by level */

/* show by level */
@keyframes createRoomByLevel {
  0% { 
    z-index: -1;
  }
  100% { 
    z-index: 5;
  }
}
/* show by level */

@keyframes show {
  10% { 
    opacity: 0; 
  }
  85% { 
    opacity: 0; 
  }
  95% { 
    opacity: .6; 
  }
  100% { 
    opacity: 1; 
  }
}

@keyframes showFull {
  0% { 
    opacity: .4; 
  }
  100% { 
    opacity: 1; 
  }
}

@keyframes pointerEvent {
  0% { 
    pointer-events:none;
  }
  99% { 
    pointer-events:none;
  }
  100% { 
    pointer-events:auto;
  }
}

@keyframes sizeForCursorSmall {
  0% { 
    width: 460px;
    height: 200px;
    z-index: 3;
  }
  90% { 
    width: 460px;
    height: 200px;
    z-index: 3;
  }
  100% { 
    width: 200px;
    height: 150px;
    z-index: 0;
  }
}

@keyframes sizeForCursorNone {
  0% { 
    width: 100%;
    height: 100%;
    background: var(--third-color);
    z-index: 3;
  }
  90% { 
    width: 100%;
    height: 100%;
    background: var(--third-color);
    z-index: 3;
  }
  100% { 
    width: 0px;
    height: 0px;
    background: transparent;
    z-index: 0;
  }
}

@keyframes AvatarPointsMoney {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes gameSwitchHex_fadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 
