.UnAbleToCreate{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  height: 468px;
  background: var(--grey-simple);
  cursor: default;
  position: absolute;
  z-index: 6;
}

.UnAbleToCreate-text{
  display: flex;
  color: var(--main-wave);
  font-size: 1.6rem;
  text-shadow: -0.01em -0.01em 0.01em #000;
  position: absolute;
  bottom: 114px;
}

