/* Infinity Loader*/

.Loaing_infinity_center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  opacity: 0;
  position: absolute;
  animation: show .64s forwards;
}

.Loaing_infinity_wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, var(--main-color-third), var(--brown));
  margin: 10px;
  animation: Loaing_infinity_wave 1s linear infinite;
  border-radius: 20px;
}

.Loaing_infinity_wave:nth-child(2) {
  animation-delay: 0.1s;
}

.Loaing_infinity_wave:nth-child(3) {
  animation-delay: 0.2s;
}

.Loaing_infinity_wave:nth-child(4) {
  animation-delay: 0.3s;
}

.Loaing_infinity_wave:nth-child(5) {
  animation-delay: 0.4s;
}

.Loaing_infinity_wave:nth-child(6) {
  animation-delay: 0.5s;
}
.Loaing_infinity_wave:nth-child(7) {
  animation-delay: 0.6s;
}

.Loaing_infinity_wave:nth-child(8) {
  animation-delay: 0.7s;
}

.Loaing_infinity_wave:nth-child(9) {
  animation-delay: 0.8s;
}

.Loaing_infinity_wave:nth-child(10) {
  animation-delay: 0.9s;
}

/* Infinity Loader*/

/* Simple Loader*/

.loader_simple_spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.loader_simple_spinner div {
  transform-origin: 40px 40px;
  animation: loader_simple_spinning 1.2s linear infinite;
}

.loader_simple_spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--main-color-third);
}

.loader_simple_spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.loader_simple_spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.loader_simple_spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.loader_simple_spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.loader_simple_spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.loader_simple_spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.loader_simple_spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.loader_simple_spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.loader_simple_spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.loader_simple_spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.loader_simple_spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.loader_simple_spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

/* Simple Loader*/

/* Hexagon */

.LoadingHexagon {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.LoadingHexagon div {
  background: var(--main-hex);
  width: 24px;
  height: 12px;
  margin-top: 6px;
  position: absolute;
  animation: hexagonRun 2s infinite backwards;
}

.LoadingHexagon div:after, .LoadingHexagon div:before {
  content: '';
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
}

.LoadingHexagon div:after {
  top: -6px;
  border-bottom: 6px solid var(--main-hex);
}

.LoadingHexagon div:before {
  bottom: -6px;
  border-top: 6px solid var(--main-hex);
}

.LoadingHexagon div:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  left: -28px;
  top: 0;
}

.LoadingHexagon div:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  left: -14px;
  top: 22px;
}

.LoadingHexagon div:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  left: 14px;
  top: 22px;
}

.LoadingHexagon div:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  left: 28px;
  top: 0;
}

.LoadingHexagon div:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  left: 14px;
  top: -22px;
}

.LoadingHexagon div:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  left: -14px;
  top: -22px;
}

.LoadingHexagon div:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  left: 0;
  top: 0;
}

/* Hexagon */

/* Arrow Loader */

.guest_arrow, .guest_arrow:before{
  position: absolute;
  left: 50%;
}
  
.guest_arrow{
  width: 40px;
  height: 40px;
  opacity: 1;
  margin: -20px 0 0 -20px;
  transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  top: 70%;
}

.guest_arrow:before{
  content: '';
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px white solid;
  border-bottom: 1px white solid;
  animation: guestArrow 2s infinite;
}

/* Arrow Loader */

/* Big Hexagon */

.LoadingHexagonBig {
  width: 200px;
  height: 200px;
  /* margin-left: -100px;
  margin-top: -100px; */
  /* position: absolute;
  left: 50%;
  top: 50%; */
  /* animation: hide 3s forwards; */
  position: relative;
  z-index: 4;
}

.LoadingHexagonBig_warm {
  width: 30px;
  height: 17px;
  background: var(--main-wave);
  position: absolute;
  top: 5px;
  animation-name: hexagonBigRunFade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: hexagonBigRunFade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.hexHead1 {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.hexHead2 {
  transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}

.bigHex {
  height: 30px;
  width: 30px;
  transition: all .3s;
  -webkit-transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.center_bigHex {
  margin-left: -15px;
  margin-top: -15px;
  animation-name: hexagonBigRun;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: hexagonBigRun;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.hexColumn1 {
  margin-left: -47px;
  margin-top: -15px;
}

.hexColumn2 {
  margin-left: -31px;
  margin-top: -43px;
}

.hexColumn3 {
  margin-left: 1px;
  margin-top: -43px;
}

.hexColumn4 {
  margin-left: 17px;
  margin-top: -15px;
}

.hexColumn5 {
  margin-left: -31px;
  margin-top: 13px;
}

.hexColumn6 {
  margin-left: 1px;
  margin-top: 13px;
}

.hexColumn7 {
  margin-left: -63px;
  margin-top: -43px;
}

.hexColumn8 {
  margin-left: 33px;
  margin-top: -43px;
}

.hexColumn9 {
  margin-left: -15px;
  margin-top: 41px;
}

.hexColumn10 {
  margin-left: -63px;
  margin-top: 13px;
}

.hexColumn11 {
  margin-left: 33px;
  margin-top: 13px;
}

.hexColumn12 {
  margin-left: -15px;
  margin-top: -71px;
}

.hexColumn13 {
  margin-left: -47px;
  margin-top: -71px;
}

.hexColumn14 {
  margin-left: 17px;
  margin-top: -71px;
}

.hexColumn15 {
  margin-left: -47px;
  margin-top: 41px;
}

.hexColumn16 {
  margin-left: 17px;
  margin-top: 41px;
}

.hexColumn17 {
  margin-left: -79px;
  margin-top: -15px;
}

.hexColumn18 {
  margin-left: 49px;
  margin-top: -15px;
}

.hexColumn19 {
  margin-left: -63px;
  margin-top: -99px;
}

.hexColumn20 {
  margin-left: 33px;
  margin-top: -99px;
}

.hexColumn21 {
  margin-left: 1px;
  margin-top: -99px;
}

.hexColumn22 {
  margin-left: -31px;
  margin-top: -99px;
}

.hexColumn23 {
  margin-left: -63px;
  margin-top: 69px;
}

.hexColumn24 {
  margin-left: 33px;
  margin-top: 69px;
}

.hexColumn25 {
  margin-left: 1px;
  margin-top: 69px;
}

.hexColumn26 {
  margin-left: -31px;
  margin-top: 69px;
}

.hexColumn27 {
  margin-left: -95px;
  margin-top: -43px;
}

.hexColumn28 {
  margin-left: -95px;
  margin-top: 13px;
}

.hexColumn29 {
  margin-left: 49px;
  margin-top: 41px;
}

.hexColumn30 {
  margin-left: -79px;
  margin-top: -71px;
}

.hexColumn31 {
  margin-left: -111px;
  margin-top: -15px;
}

.hexColumn32 {
  margin-left: 65px;
  margin-top: -43px;
}

.hexColumn33 {
  margin-left: 65px;
  margin-top: 13px;
}

.hexColumn34 {
  margin-left: -79px;
  margin-top: 41px;
}

.hexColumn35 {
  margin-left: 49px;
  margin-top: -71px;
}

.hexColumn36 {
  margin-left: 81px;
  margin-top: -15px;
}

.bigHexRow1 {
  animation-name: hexagonBigRun;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .2s;
  -webkit-animation-name: hexagonBigRun;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .2s;
}

.bigHexRow2 {
  animation-name: hexagonBigRun;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .4s;
  -webkit-animation-name: hexagonBigRun;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .4s;
}

.bigHexRow3 {
  animation-name: hexagonBigRun;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .6s;
  -webkit-animation-name: hexagonBigRun;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .6s;
}

.bigHexRow1 > .LoadingHexagonBig_warm {
  animation-name: hexagonBigRunFade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .2s;
  -webkit-animation-name: hexagonBigRunFade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .2s;
}

.bigHexRow2 > .LoadingHexagonBig_warm {
  animation-name: hexagonBigRunFade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .4s;
  -webkit-animation-name: hexagonBigRunFade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .4s;
}

.bigHexRow3 > .LoadingHexagonBig_warm {
  animation-name: hexagonBigRunFade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .6s;
  -webkit-animation-name: hexagonBigRunFade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .6s;
}

/* Big Hexagon */

/* Dots Loader */

.Loading_dots{
  background: transparent;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--brown);
  margin: .25rem;
}

.Loading_dots .dot:nth-last-child(1) {
  animation: jumpingAnimation 1.2s 0.6s linear infinite;
}

.Loading_dots .dot:nth-last-child(2) {
  animation: jumpingAnimation 1.2s 0.3s linear infinite;
}

.Loading_dots .dot:nth-last-child(3) {
  animation: jumpingAnimation 1.2s 0s linear infinite;
}

/* Dots Loader */

/* Circle Loader*/

.Loading-circle {
  width: 70px;
  height: 70px;
  background: var(--main-wave);
  border-radius: 50px;
  mask: radial-gradient(circle 31px at 50% calc(100% + 13px),#000 95%,#0000) top 4px left 50%,
  radial-gradient(circle 31px,#000 95%,#0000) center,
  radial-gradient(circle 31px at 50% -13px,#000 95%,#0000) bottom 4px left 50%,
  linear-gradient(#000 0 0);;
  -webkit-mask: radial-gradient(circle 31px at 50% calc(100% + 13px),#000 95%,#0000) top 4px left 50%,
    radial-gradient(circle 31px,#000 95%,#0000) center,
    radial-gradient(circle 31px at 50% -13px,#000 95%,#0000) bottom 4px left 50%,
    linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  animation: Loader-loadingCircle 1.5s infinite;
}

/* Circle Loader*/

/* Circle Numbers loader */
.LoaderCircleNumbers {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scale: 0.7;
  animation: hide 3s forwards;
}

.LoaderCircleNumbers-central {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  box-shadow: 0.5em 1em 1em Var(--main-wave),
    -0.5em 0.5em 1em var(--main-color),
    0.5em -0.5em 1em var(--brown),
    -0.5em -0.5em 1em var(--main-hex);
}

.LoaderCircleNumbers-external-shadow {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0.5em 0.5em 3em Var(--main-wave),
    -0.5em 0.5em 3em var(--main-color),
    0.5em -0.5em 3em var(--brown),
    -0.5em -0.5em 3em var(--main-hex);
  z-index: 999;
  animation: LoaderCircleNumbers-rotate 3s linear infinite;
  background-color: var(--grey);
}

.LoaderCircleNumbers-intern {
  position: absolute;
  font: bold .98rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-simple);
  color: white;
  z-index: 9999;
}

.LoaderCircleNumbers-intern::before {
  content: "100%";
  animation: LoaderCircleNumbers-percent 2s ease-in-out forwards;
}
/* Circle Numbers loader */