.Radar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 1.7);
  aspect-ratio: 1 / 1;
  background: #000000;
  border: 2px solid var(--main-wave);
  border-radius: 50%;
  box-shadow: var(--box-shadow-signin);
  overflow: hidden;
  position: relative;
}

.Radar::after {
  content: "";
  position: absolute;
  width: calc(100% / 1.7);
  height: 2px;
  left: 50%;
  top: 50%;
  background-color: var(--main-wave);
  animation: Radar-rotate 3s linear infinite;
  z-index: 6;
  transform-origin: 0 0;
}

.Radar::before {
  content: "";
  position: absolute;
  width: calc(100% / 1.7);
  height: 0px;
  left: 50%;
  top: 50%;
  transform: rotate(-4deg);
  background-color: var(--main-wave);
  animation: Radar-rotate 3s linear infinite;
  box-shadow: 0 0 10px 4px var(--main-wave), 0 0 70px 10px var(--main-wave);
  z-index: 6;
  transform-origin: 0 0;
}

.Radar-circle {
  width: calc(100% / 2);
  aspect-ratio: 1 / 1;
  border: 2px solid var(--main-wave);
  border-radius: 50%;
}

.Radar-circle::before {
  content: "";
  position: absolute;
  width: calc(100% / 4);
  aspect-ratio: 1 / 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--main-wave);
  border-radius: 50%;
}

.Radar-circle::after {
  content: "";
  position: absolute;
  width: calc(100% / 4 + 100% / 2);
  aspect-ratio: 1 / 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--main-wave);
  border-radius: 50%;
}

.Radar-dot1 {
  width: 4px;
  height: 4px;
  top: 30%;
  background-color: #18d322;
  border-radius: 50%;
  position: absolute;
  animation: Radar-doted 1.5s ease infinite;
}

.Radar-dot2 {
  width: 4px;
  height: 4px;
  top: 80%;
  right: 30%;
  background-color: #18d322;
  border-radius: 50%;
  position: absolute;
  animation: Radar-doted 1.9s 1.2s ease infinite;
}

.Radar-dot3 {
  width: 4px;
  height: 4px;
  top: 60%;
  right: 20%;
  background-color: #18d322;
  border-radius: 50%;
  position: absolute;
  animation: Radar-doted 2.4s 1.8s ease infinite;
}

.Radar-dot4 {
  width: 4px;
  height: 4px;
  top: 30%;
  right: 90%;
  background-color: yellow;
  border-radius: 50%;
  position: absolute;
  animation: Radar-doted 3s 4.6s ease infinite;
}