.EventCup{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--off-red-trans);
  border-radius: .4rem;
  position: absolute;
}

.EventCup_loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: .4rem;
  background: var(--off-red-trans);
  box-shadow: var(--box-shadow-features);
  position: absolute;
  animation: hide 3s forwards, EventCupCursorNone .9s 2.2s forwards;
  z-index: 3;
}

.EventCup_events{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 338px;
  height: calc(100% / 1.8);
  border: 4px solid var(--brown-trans);
  border-radius: .8rem;
  background-image: url('../../../../Images/bluishBgr.jpg');
  box-shadow: var(--box-shadow-features);
  opacity: 0;
  position: relative;
  animation: show 3.3s forwards;
  z-index: 0;
}

.EventListSelect {
  display: flex;
  width: calc(100% - 14px);
  height: 48px;
  line-height: 3;
  overflow: hidden;
  border-radius: .25em;
  margin: 1rem .3rem;
  position: relative;
  z-index: 2;
}

.EventListSelect::after {
  content: '\25B2';
  padding: 0 1em;
  background: var(--grey-light);
  color: var(--main-wave);
  cursor: pointer;
  pointer-events: none;
  transition: .25s all ease;
  position: absolute;
  top: 0;
  right: 0;
}

.EventListSelect:hover::after {
  color: var(--main-color);
}

.EventListSelect select {
  flex: 1;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0!important;
  background: var(--brown-first);
  color: var(--brown);
  padding: 0 .5em;
  cursor:pointer;
  font: var(--signin-form-second);
}

.singleEvent{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 88px);
  background: rgba(0, 0, 0, .5);
  border-radius: .4rem;
  position: absolute;
  top: 0;
  z-index: 2;

}

.singleEvent_selected{
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-hex);
  font: var(--signin-submit);
  opacity: 0;
  animation: show 3.8s forwards;
}

.singleEvent_selected_loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  position: absolute;
  z-index: 3;
  animation: hide 3.2s forwards, EventCupCursorNone .9s 2.8s forwards;
}

