.RoomGameNumbers-GameScore{
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--forth-nav-trans);
  border-radius: .6rem;
  position: absolute;
  animation: GameScoreoBgr 3s alternate ease-out infinite;
}

@media (max-height: 799px) {

  .RoomGameNumbers-GameScore{
    width: 84px;
    height: 48px;
    top: -52px;
  }

  .RoomGameNumbers-GameScore-title{
    font: bold 2.2rem 'Raleway', sans-serif; ;
    color: var(--secondary-color);
    text-shadow: var(--text-shadow-extra);
    cursor: default;
  }
}

@media (max-width: 480px) and (min-height: 800px){
  
  .RoomGameNumbers-GameScore{
    width: 84px;
    height: 48px;
    top: -20px;
  }

  .RoomGameNumbers-GameScore-title{
    font: bold 2.2rem 'Raleway', sans-serif; ;
    color: var(--secondary-color);
    text-shadow: var(--text-shadow-extra);
    cursor: default;
  }
}

@media (min-width: 481px) and (max-width: 767px) and (min-height: 800px) {
  
  .RoomGameNumbers-GameScore{
    width: 84px;
    height: 48px;
    top: -20px;
  }

  .RoomGameNumbers-GameScore-title{
    font: bold 2.2rem 'Raleway', sans-serif; ;
    color: var(--secondary-color);
    text-shadow: var(--text-shadow-extra);
    cursor: default;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-height: 800px) {
  
  .RoomGameNumbers-GameScore{
    width: 118px;
    height: 64px;
    top: 8px;
  }

  .RoomGameNumbers-GameScore-title{
    font: bold 2.8rem 'Raleway', sans-serif; ;
    color: var(--secondary-color);
    text-shadow: var(--text-shadow-extra);
    cursor: default;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-height: 800px) {
  
  .RoomGameNumbers-GameScore{
    width: 118px;
    height: 64px;
    top: 8px;
  }

  .RoomGameNumbers-GameScore-title{
    font: bold 2.8rem 'Raleway', sans-serif; ;
    color: var(--secondary-color);
    text-shadow: var(--text-shadow-extra);
    cursor: default;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) and (min-height: 800px) {
  
  .RoomGameNumbers-GameScore{
    width: 118px;
    height: 64px;
    top: 8px;
  }

  .RoomGameNumbers-GameScore-title{
    font: bold 2.8rem 'Raleway', sans-serif; ;
    color: var(--secondary-color);
    text-shadow: var(--text-shadow-extra);
    cursor: default;
  }
}

@media (min-width: 1600px) and (min-height: 800px) {
  
  .RoomGameNumbers-GameScore{
    width: 118px;
    height: 64px;
    top: 8px;
  }

  .RoomGameNumbers-GameScore-title{
    font: bold 2.8rem 'Raleway', sans-serif; ;
    color: var(--secondary-color);
    text-shadow: var(--text-shadow-extra);
    cursor: default;
  }
}