.NavBtnPlace{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  background: var(--third-color);
  box-shadow: var(--box-shadow-features);
  border-radius: 10px;
  position: relative;
  z-index: 4;
}

@media (max-width: 480px) {
  
  .NavBtnPlace{
    width: 100%;
    min-width: 350px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .NavBtnPlace{
    width: 100%;
    max-width: 640px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .NavBtnPlace{
    width: 640px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .NavBtnPlace{
    width: 640px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .NavBtnPlace{
    width: 640px;
  }
}

@media (min-width: 1600px) {
  
  .NavBtnPlace{
    width: 640px;
  }
}

.NavBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background: var(--nav-btn);
  background-size: 250%;
  background-position: left;
  color: var(--main-nav);
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
  position: relative;
}

@media (max-width: 480px) {
  
  .NavBtn{
    width: 102px;
    margin: .1rem;
    font: 500 .62rem 'Raleway', sans-serif;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .NavBtn{
    width: 106px;
    margin: .1rem;
    font: 500 .72rem 'Raleway', sans-serif;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .NavBtn{
    width: 140px;
    margin: .5rem;
    font: 500 .84rem 'Raleway', sans-serif;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .NavBtn{
    width: 140px;
    margin: .5rem;
    font: 500 .84rem 'Raleway', sans-serif;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .NavBtn{
    width: 140px;
    margin: .5rem;
    font: 500 .84rem 'Raleway', sans-serif;
  }
}

@media (min-width: 1600px) {
  
  .NavBtn{
    width: 140px;
    margin: .5rem;
    font: 500 .84rem 'Raleway', sans-serif;
  }
}

.NavBtn::before {
  content: " " attr(data) " ";
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-nav);
  width: 97%;
  height: 90%;
  border-radius: 8px;
  background-color: var(--forth-nav);
  background-size: 200%;
  transition-duration: 1s;
  position: absolute;
}

.NavBtn:hover {
  background-position: right;
  transition-duration: 1s;
}

.NavBtn:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.NavBtn:active {
  transform: scale(0.95);
}