/* tournament temporary not available */
.temporary{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  background: yellow;
  border-radius: .4rem;
  font: var(--avatar-table);
  opacity: 0;
  cursor: default;
  position: absolute;
  bottom: 44px;
  animation: show 4.6s forwards;
}
/* tournament temporary not available */