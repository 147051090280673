@keyframes gameRoomsBackgroundColor {

  0% { 
    background: rgba(0, 0, 0, .2);
  }
  100% { 
    background: rgba(76, 49, 21, .4);
  }

}

@keyframes showFull {

  0% { 
    opacity: .4; 
  }
  100% { 
    opacity: 1; 
  }

}

@keyframes pointerEvent {

  0% { 
    pointer-events:none;
  }

  99% { 
    pointer-events:none;
  }

  100% { 
    pointer-events:auto;
  }
  
}