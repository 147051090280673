.RoomGameNumbers-gameStatus{
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  position: absolute;
  z-index: 4;
  animation: RoomGameNumbers-gameStatus-hide 3.4s 3s linear forwards;
}

@media (max-width: 480px) {
  
  .RoomGameNumbers-gameStatus{
    width: calc(100% / 1.14);
    max-width: 420px;
    min-width: 350px;
    height: 360px;
    bottom: 80px;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .RoomGameNumbers-gameStatus{
    width: 420px;
    height: 420px;
    bottom: 70px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .RoomGameNumbers-gameStatus{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .RoomGameNumbers-gameStatus{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .RoomGameNumbers-gameStatus{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .RoomGameNumbers-gameStatus{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 1600px) {
  
  .RoomGameNumbers-gameStatus{
    width: 420px;
    height: 420px;
  }
}

.RoomGameNumbers-gameStatus::after{
  content: "";
  display: flex;
  box-shadow: var(--box-shadow-signin);
  background: var(--grey-simple);
  background: var(--forth-nav);
  background-image: url("../../../../../Images/hexbackground.png");
  background-repeat: no-repeat;
  background-size: 400px 400px;
  border-radius: 50%;
  position: absolute;
}

@media (max-width: 480px) {
  
  .RoomGameNumbers-gameStatus::after{
    width: 350px;
    height: 350px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .RoomGameNumbers-gameStatus::after{
    width: 400px;
    height: 400px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .RoomGameNumbers-gameStatus::after{
    width: 400px;
    height: 400px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .RoomGameNumbers-gameStatus::after{
    width: 400px;
    height: 400px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .RoomGameNumbers-gameStatus::after{
    width: 400px;
    height: 400px;
  }
}

@media (min-width: 1600px) {
  
  .RoomGameNumbers-gameStatus::after{
    width: 400px;
    height: 400px;
  }
}


.RoomGameNumbers-gameStatus-text {
  font: var(--gameStarted);
  color: var(--main-wave);
  text-shadow: var(--text-shadow-simple);
  padding: .2rem;
  background: var(--forth-nav);
  border-radius: 1rem;
  position: absolute;
  z-index: 5;
}

/* for rounds check RoomGameNumbers.css */





