.AvatarTitlesCard{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  opacity: .4;
  animation: showFull 3s forwards;
}

@media (max-width: 480px) {
  
  .AvatarTitlesCard{
    width: 350px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .AvatarTitlesCard{
    width: 472px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .AvatarTitlesCard{
    width: 512px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .AvatarTitlesCard{
    width: 512px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .AvatarTitlesCard{
    width: 512px;
  }
}

@media (min-width: 1600px) {
  
  .AvatarTitlesCard{
    width: 512px;
  }
}

.table_container {
  display: table;
  text-align: left;
  width: 90%;
  color: var(--forth-color);
  box-shadow: var(--box-shadow-menu);
  position: relative;
}

.table_container td{
  font: var(--avatar-table);
  height: 30px;
  padding: .2rem;
}

.table_container tr:nth-child(odd) {
  background-color: var(--brown);
}

.table_container tr:nth-child(even) {
  background-color: var(--brown-second);
}

tr td:not(:first-child){
  width: 20%;
}

.table_container td:hover {
  background-color: var(--brown-first);
  color: var(--white-second);
  opacity: .9;
  transform: translate3d(2px, -2px, 0);
  cursor: pointer;
  transition: all .82s;
}