.RoomGameNumbersExplanation{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  opacity: 0;
  border-radius: 50%;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  animation: show 5.4s forwards;
  z-index: 3;
}

@media (max-width: 699px) {
  
  .RoomGameNumbersExplanation{
    left: 14px;
    top: 40px;
  }
}

@media (min-width: 700px) {

  .RoomGameNumbersExplanation{
    left: 44px;
    bottom: 40px;
  }
}

.RoomGameNumbersExplanationTextContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transform: scale(0, 0);
  background: var(--forth-nav);
  border-radius: .8rem;
  box-shadow: var(--box-shadow-signin);
  cursor: default;
  overflow: scroll;
  overflow-x: hidden;
  position: absolute;
  z-index: 3;
}

.RoomGameNumbersExplanationTextContainer::-webkit-scrollbar{
  width: .9rem;
}

.RoomGameNumbersExplanationTextContainer::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 .8rem var(--white-first);
  border-radius: .4rem;
}

.RoomGameNumbersExplanationTextContainer::-webkit-scrollbar-thumb {
  border-radius: .8rem;
  -webkit-box-shadow: inset 0 0 6px var(--main-nav); 
}

@media (max-width: 447px) {
  
  .RoomGameNumbersExplanationTextContainer{
    width: 350px;
    height: 360px;
    top: 40px;
    left: 4px;
  }
}

@media (min-width: 448px) and (max-width: 511px) {
  
  .RoomGameNumbersExplanationTextContainer{
    width: 420px;
    height: 360px;
    top: 40px;
    left: 14px;
  }
}

@media (min-width: 512px) and (max-width: 699px) {
  
  .RoomGameNumbersExplanationTextContainer{
    width: 420px;
    height: 420px;
    top: -20px;
    left: 64px;
  }
}

@media (min-width: 700px) {

  .RoomGameNumbersExplanationTextContainer{
    width: 420px;
    height: 420px;
    bottom: 52px;
    left: 84px;
  }
}

.RoomGameNumbersExplanationTextHeader{
  color: var(--main-hex);
  font: bold .82rem 'Raleway', sans-serif;
  padding: 1rem .8rem;
  text-align: center;
  text-shadow: var(--text-shadow-simple);
}

.RoomGameNumbersExplanationText{
  color: var(--main-nav);
  font: 600 .8rem 'Raleway', sans-serif;
  padding: .4rem .8rem;
  text-align: left;
  text-shadow: var(--text-shadow-simple);
}

.RoomGameNumbersExplanationTextHighLight{
  font-weight: bolder;
  color: var(--main-hex);
}

.RoomGameNumbersExplanationIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.RoomGameNumbersExplanation:hover .RoomGameNumbersExplanationTextContainer{
  transition: .24s all ease-in-out;
  transform: scale(1, 1);
}

.RoomGameNumbersExplanation:not(:hover) .RoomGameNumbersExplanationTextContainer{
  transition: .14s .4s all ease-in-out;
  transform: scale(0, 0);
}

.RoomGameNumbersExplanationIconImg{
  width: 40px;
}