/* main body */

/* Small Smartphones: max-width: 480px */
/* Larger Smartphones: min-width: 481px and max-width: 767px */
/* Portrait Tablets: min-width: 768px and max-width: 991px */
/* Landscape Tablets: min-width: 992px and max-width: 1199px */
/* Laptops: min-width: 1200px and max-width: 1599px */
/* Desktops: min-width: 1600px */

@media (max-width: 480px) {
  /* Styles for small smartphones */
}

@media (min-width: 481px) and (max-width: 767px) {
  /* Styles for larger smartphones */
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Styles for portrait tablets */
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Styles for landscape tablets */
}

@media (min-width: 1200px) and (max-width: 1599px) {
  /* Styles for laptops */
}

@media (min-width: 1600px) {
  /* Styles for large desktops */
}


:root {

  /* colors */

  --black: black;
  --black-light: #181818;
  --white: white;
  --white-first: #aaaaaa;
  --white-second: #f5deb3;
  --brown: #964b00;
  --brown-trans: rgba(150, 75, 0, .3);
  --brown-first: #351e0b;
  --brown-first-trans: rgba(53, 30, 11, .3);
  --brown-second: #7A3E07;
  --brown-second-trans: rgba(122, 62, 7, .3);
  --brownish: rgba(152, 94, 64, 1);
  --brownish-trans: rgba(152, 94, 64, .2);
  --grey: #222;
  --grey-simple-dark: rgba(29, 28, 26, .94);
  --grey-simple: rgba(29, 28, 26, .78);
  --grey-first: rgba(29, 28, 26, .38);
  --grey-second: #333;
  --grey-light: rgba(145, 144, 144, 0.14);
  --grey-light-trans: rgba(145, 144, 144, 0.08);

  --green-first: #33CC36; 
  --on-green: #008000;
  --on-green-trans: rgba(0, 128, 0, .3);
  --green-second: #B8CC33;

  --off-red: #ff0000;
  --off-red-trans: rgba(255, 0, 0, .3);

  --main-color: #F8B54F;
  --main-nav: #ffd277;
  --main-color-first: #f7b95e;
  --main-color-second: #da740e;
  --main-color-third: #da740e;
  --main-color-third-trans: rgba(218, 116, 14, .7);
  --main-hex: #da7413;
  --main-wave: #FF6700;
  --main-wave-trans: rgba(255, 102, 0, 0.651);
  --main-wave-trans-extra: rgba(255, 102, 0, 0.251);

  --settings-color: #7770BB;
  --settings-color-trans: rgba(119, 112, 187, .2);
  --secondary-color: #00b7ff;
  --secondary-color-trans: rgba(0, 183, 255, .2);
  --secondary-color-first: #045575;
  --secondary-color-second: #00ffff;

  --secondary-color-third: rgb(7, 24, 46);
  --third-color: rgba(0, 0, 0, .2);
  --third-color-first: rgb(1, 13, 29);
  --third-color-second: rgb(7, 24, 46);
  --forth-color: rgb(0, 0, 46);
  --forth-nav: rgba(0, 0, 0, 0.842);
  --forth-nav-trans: rgba(0, 0, 0, 0.52);
  
  --logo-bgr-color: #3D3221;

  --bgr-main: linear-gradient(to bottom, #353532 0%, #181813 100%);
  --bgr-main-light: linear-gradient(to bottom, rgba(25, 9, 167, 0.58) 0%, rgba(58, 32, 3, 0.3) 100%);
  --general-button-bgr: linear-gradient(90deg,#d48714,#d46c0a,#c2a74d,#F3C343,#351009,#C1872B,#B59B6A);
  --signin-background: linear-gradient(#000,#F8B54F, #000);
  --nav-btn: linear-gradient(to right,#77530a,#ffd277,#77530a,#77530a,#ffd277,#77530a);
  --guest-front: linear-gradient(-45deg, #f89b29 0%, #f0b173 100% );
  --guest-content: linear-gradient(-45deg, #a3671e 0%, #fd6601 100% );
  --quote-bgr: linear-gradient(-45deg, rgba(248, 155, 41, .6) 0%, rgba(255, 123, 15, .4) 100% );

  /* fonts */

  --general-button-fonts: 600 1.1rem 'Raleway', sans-serif; 
  --general-button-fonts-secondary: 500 1.1rem 'Raleway', sans-serif; 

  --signin-form-first: 1rem 'Raleway', sans-serif;
  --signin-form-second: normal 500 .95rem 'Raleway', sans-serif;
  --signin-form-third: normal 500 .85rem 'Raleway', sans-serif;
  --signin-form-forth: 600 .75rem 'Raleway', sans-serif;
  --signin-form-fifth: 600 .8rem 'Raleway', sans-serif;
  --signin-submit: 600 1rem 'Raleway', sans-serif;
  --logged-userName: bold .68rem 'Raleway', sans-serif;
  --avatar-table: bold .8rem 'Raleway', sans-serif;
  --nav-font: .82rem 'Raleway', sans-serif;
  --redirect-font: bold 2rem 'Raleway', sans-serif;
  --editFeatures-font: bold .64rem 'Raleway';
  --settingsFeatures-font: bold .92rem 'Raleway';
  --Runners-Font: bold 7rem 'Raleway', sans-serif;
  --guest-font: 500 1.02rem 'Raleway';
  --countDownTimer: bold .88rem 'Raleway', sans-serif;
  --gameStarted: bold 3.2rem 'Raleway', sans-serif;
  /* shapes */

  --hexagon: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  --hexagon-secondary: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  --hexagon-third: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
  --triangle: polygon(50% 100%, 0 0, 100% 0);

    /* text shadows */

    --text-shadow: 0 0 .5em rgba(250, 133, 0, 0.2);

    --text-shadow-simple: -2px 1px 2px rgba(0,0,0,0.57);

    --text-shadow-extra: 5px 5px 5px rgba(0,0,0,0.3);

   /* box shadows */

   --box-shadow-menu: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
   --box-shadow-signin: 0 15px 35px rgba(0, 0, 0, .9);
   --box-shadow_bonuses: 0px 0px 20px 1px #ffbb763f;
   --box-shadow-features: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
   --box-shadow-feature-items: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
   --box-shadow-nav-set: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 -1px 0 0 rgba(0, 0, 0, 0.1) inset, 0 -1.31em 1.31em -1.31em rgba(0, 0, 0, 0.3) inset, 0 0 1px 0 rgba(0, 0, 0, 0.1);
   --box-shadow-redirect: 75px 75px 5px -20px var(--brown-first), -75px 75px 5px -20px var(--main-color-first), -75px -75px 5px -20px var(--brown-first), 75px -75px 5px -20px var(--main-wave);
   --box-shadow-createRoom-1: 0 0 1em .25em var(--grey-first),
   0 0 4em 1em var(--main-color-third-trans),
   inset 0 0 .75em .25em var(--brown-second-trans);
   --box-shadow-createRoom-2: 0 0 1em .25em var(--brown-second-trans),
   0 0 4em 2em var(--main-color-third-trans),
   inset 0 0 .75em .25em var(--main-color-first);
   --box-shadow-createRoom-3: 0 0 0.6em .25em var(--main-color-first),
   0 0 2.5em 2em var(--main-color-third-trans),
   inset 0 0 .5em .25em var(--brown-second-trans);

  /* animations */


  --btn-animation: goldenoBgrLight 7s alternate infinite;
  --hover_animation:  goldenoBgr 3.6s alternate ease-out infinite;
  --logo-animation:  goldenoBgr 3s alternate ease-out infinite;
  --forms: show .4s forwards, pointerEvent 1.6s forwards, formShow 1.1s forwards;
  --create-room-noclick: show .4s forwards, pointerEvent 1.4s forwards, formShow 1.1s forwards;

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background: orange;
  animation: color 36s infinite linear; 
}

.App{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Main{
  display: grid;
  grid-template-rows: 2fr 7fr 1fr;
  justify-content: center;
  height: 100dvh;
  min-height: 712px;
  align-items: center;
  background: var(--bgr-main);
  background-image: url('../../public/image/bodyBgr1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 480px) {

  .Main{
    min-width: 350px;
    width: 100vw;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  .Main{
    width: 100vw;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .Main{
    width: 100vw;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .Main{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {

  .Main{
    width: 800px;
  }
}

@media (min-width: 1600px) {

  .Main{
    width: 800px;
  }
}

.Main::after{
  content: "";
  display: flex;
  justify-content: center;
  height: 100dvh;
  min-height: 712px;
  align-items: center;
  background: #290000;
  opacity: .5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media (max-width: 480px) {

  .Main::after{
    min-width: 350px;
    width: 100vw;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  .Main::after{
    width: 100vw;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .Main::after{
    width: 100vw;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .Main::after{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .Main::after{
    width: 800px;
  }
}

@media (min-width: 1600px) {

  .Main::after{
    width: 800px;
  }
}

.close{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  box-shadow: var(--box-shadow-features);
  position: absolute;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  z-index: 8;
}

.close img{
  display: block;
  width: 32px;
  height: 32px;
  background: var(--main-color-second);
  border: 4px solid black;
  border-radius: 50%;
  transition: all .2s;
}

.close img:hover{
  background: var(--off-red);
  border: 1px solid var(--off-red);
}

.hidden{
  display: none;
}

.disable{
  pointer-events: none;
  background: var(--brown) !important;
}

.disable-link{
  pointer-events: none;
}

.transparent{
  width: auto;
  height: 100%;
  opacity: 0;
  position: relative;
  z-index: 0;
}

.greenBorder{
  border: 2px outset var(--green-first);
}

.redBorder{
  border: 2px outset var(--off-red);
}