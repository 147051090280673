.HeaderGamePlayersData{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100%;
  min-height: 200px;
  position: absolute;
  background: black;
  color: var(--main-color);
  font: bold .88rem 'Raleway', sans-serif;;
  border: 1px outset wheat;
  border-radius: .24rem;
  box-shadow: var(--box-shadow-signin);
  text-shadow: var(--text-shadow);
  cursor: default;
  transform: translateY(100%); 
  z-index: 4;
}

.HeaderGamePlayersDataTable{
  width: 90%;
}

.HeaderGamePlayersDataTableName{
  color: var(--off-red);
}

.HeaderGamePlayersDataTableRoom{
  color: var(--white-second);
}






