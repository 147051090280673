.AvatarBonusCard{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: .2rem;
  background: var(--grey-simple);
  box-shadow: var(--box-shadow-menu);
  opacity: 1;
  position: relative;
}

@media (max-width: 480px) {

  .AvatarBonusCard{
    width: 312px;
    height: 32px;
  }
}

@media (min-width: 481px) and (max-width: 767px){
  
  .AvatarBonusCard{
    width: 418px;
    height: 48px;
  }
}

@media (min-width: 768px) and (max-width: 991px){
  
  .AvatarBonusCard{
    width: 432px;
    height: 48px;
  }
}

@media (min-width: 992px) and (max-width: 1199px){
  
  .AvatarBonusCard{
    width: 500px;
    height: 112px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .AvatarBonusCard{
    width: 500px;
    height: 118px;
  }
}

@media (min-width: 1600px) {
  
  .AvatarBonusCard{
    width: 500px;
    height: 230px;
  }
}

.AvatarBonusCard::before{
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--third-color);
  opacity: 0;
  border: .3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

@media (max-width: 767px) {
  
  .AvatarBonusCard::before{
    display: none;
  }
}

.AvatarBonusCard-loading-circle-place{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  animation: hide 1.7s forwards;
}

.AvatarBonusCard_points, .AvatarBonusCard_coins{
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  background: var(--secondary-color-third);
  opacity: 0;
  position: relative;
  overflow: hidden;
  border-radius: .4rem;
  box-shadow: var(--box-shadow_bonuses);
  z-index: 2;
  animation: show 2s forwards;
}

@media (max-width: 480px) {
  
  .AvatarBonusCard_points, .AvatarBonusCard_coins{
    width: 72px;
    height: 72px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .AvatarBonusCard_points, .AvatarBonusCard_coins{
    width: 100px;
    height: 72px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .AvatarBonusCard_points, .AvatarBonusCard_coins{
    width: 100px;
    height: 66px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .AvatarBonusCard_points, .AvatarBonusCard_coins{
    width: 100px;
    height: 72px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .AvatarBonusCard_points, .AvatarBonusCard_coins{
    width: 100px;
    height: 160px;
  }
}

@media (min-width: 1600px) {
  
  .AvatarBonusCard_points, .AvatarBonusCard_coins{
    width: 100px;
    height: 160px;
  }
}

@media (min-width: 992px) {
  
  .AvatarBonusCard_points:hover, .AvatarBonusCard_coins:hover{
    background: var(--main-color-third);
    cursor: default;
  }
}

.AvatarBonusCard_points h2, .AvatarBonusCard_coins h2{
  background: none;
  color: var(--white);
  font-size: .8rem;
  text-shadow: var(--text-shadow-simple);
  transition: all 0.16s linear;
  z-index: 1;
}

.AvatarBonusCard_points:hover h2, .AvatarBonusCard_coins:hover h2{
  font-weight: bold;
  font-size: .9rem;
}

.AvatarBonusCard_points p, .AvatarBonusCard_coins p {
  color: var(--main-color);
  margin-top: .6rem;
  font: var(--signin-form-fifth); 
  text-shadow: var(--text-shadow-simple);
  transition: all 0.24s linear;
  z-index: 1;
}

.AvatarBonusCard_points:hover p, .AvatarBonusCard_coins:hover p{
  font-weight: bold;
  font-size: .9rem;
}

@media (min-width: 992px) {
  
  .AvatarBonusCard_points::before, .AvatarBonusCard_coins::before {
    content: '';
    width: 100px;
    background-image: linear-gradient(180deg, var(--main-color), var(--secondary-color));
    height: 130%;
    position: absolute;
    animation: AvatarPointsMoney 28s linear infinite;
    transition: all 0.2s linear;
  }
}

@media (min-width: 992px) {
  
  .AvatarBonusCard_points::after, .AvatarBonusCard_coins::after{
    content: '';
    position: absolute;
    background: var(--third-color-second);
    inset: 5px;
    border-radius: .1rem;
  }
}

@media (min-width: 992px) {
  
  .AvatarBonusCard_points:hover:before,  .AvatarBonusCard_coins:hover:before{
    background-image: linear-gradient(180deg, var(--secondary-color-first), var(--main-color-second));
    animation: AvatarPointsMoney 24s linear infinite;
  }
} 

