/* ------ Limitations Area --------- */
.GameWatchingContainer-limitations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background: var(--guest-content);
  box-shadow: var(--box-shadow-signin);
  border-radius: .8rem;
  cursor: default;
  padding: 0 18px;
  line-height: 1.5;
  animation: show 2.2s forwards, GameWatchingLimitations-dark 60s 2.2s forwards;
}

@media (max-width: 480px) {
  
  .GameWatchingContainer-limitations{
    width: calc(100% - 88px);
    min-width: 300px;
    height: calc(100% / 2.2);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .GameWatchingContainer-limitations{
    width: calc(100% - 88px);
    max-width: 540px;
    height: calc(100% / 2.2);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .GameWatchingContainer-limitations{
    width: calc(100% / 1.48);
    max-width: 542px;
    height: calc(100% / 2.2);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .GameWatchingContainer-limitations{
    width: calc(100% / 1.48);
    height: calc(100% / 2.2);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .GameWatchingContainer-limitations{
    width: calc(100% / 1.48);
    height: calc(100% / 2.2);
  }
}

@media (min-width: 1600px) {
  
  .GameWatchingContainer-limitations{
    width: calc(100% / 1.48);
    height: calc(100% / 2.2);
  }
}

@media (max-width: 480px) {
  
  .GameWatchingContainer-limitations_heading{
    font-size: 1.12rem;
    font-weight: bold;
  }
  
  .GameWatchingContainer-limitations_text{
    margin-bottom: .8rem;
    font-size: .68rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .GameWatchingContainer-limitations_heading{
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .GameWatchingContainer-limitations_text{
    margin-bottom: .8rem;
    font-size: .72rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .GameWatchingContainer-limitations_heading{
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  .GameWatchingContainer-limitations_text{
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .GameWatchingContainer-limitations_heading{
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  .GameWatchingContainer-limitations_text{
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .GameWatchingContainer-limitations_heading{
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  .GameWatchingContainer-limitations_text{
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

@media (min-width: 1600px) {
  
  .GameWatchingContainer-limitations_heading{
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  .GameWatchingContainer-limitations_text{
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

/* Button */
.GameWatchingButtons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

.GameWatchingButtons_button {
  background: rgba(0, 0, 0, .2);
  color: white;
  width: 7.5rem;
  height: 2.6rem;
  border: 0.2rem solid transparent;
  border-radius: .3rem;
  text-align: right;
  transition: all 0.6s ease;
  cursor: pointer;
}

.GameWatchingButtons_button:hover {
  background-color: black;
}

.GameWatchingButtons_button svg {
  width: 1.6rem;
  margin: -0.35rem 0.8rem 1rem;
  pointer-events: none;
  position: absolute;
  display: flex;
  transition: all 0.6s ease;
}

.GameWatchingButtons_button:hover svg {
  transform: translateX(5px);
}

.GameWatchingButtons_text{
  pointer-events: none;
  margin: 0 1rem
}
/* Button */
/* ------ Limitations Area --------- */