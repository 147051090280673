@keyframes CreateRoomEvent {

  0% { 
    pointer-events:none;
  }
  99% { 
    pointer-events:none;
  }
  100% { 
    pointer-events:auto;
  }

}

@keyframes CreateRoomShow {
  
  0%
  {
    transform: scale(0)
  }
  80%
  {
    transform: scale(0)
  }
  100%
  {
    transform: scale(1);
  }

}