/* Tournament Menu buttons Container */
.TournamentsGameAreaButtonPlace{
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--third-color);
  box-shadow: var(--box-shadow-features);
  border-radius: 10px;
  margin-top: .5rem;
  position: relative;
  z-index: 4;
}

@media (max-width: 480px) {
 
  .TournamentsGameAreaButtonPlace{
    width: 100%;
    min-width: 350px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .TournamentsGameAreaButtonPlace{
    width: 100%;
    max-width: 640px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .TournamentsGameAreaButtonPlace{
    width: calc(100% / 1.25);
    height: 76px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .TournamentsGameAreaButtonPlace{
    width: calc(100% / 1.25);
    height: 76px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .TournamentsGameAreaButtonPlace{
    width: calc(100% / 1.25);
    height: 76px;
  }
}

@media (min-width: 1600px) {
  
  .TournamentsGameAreaButtonPlace{
    width: calc(100% / 1.25);
    height: 76px;
  }
}
/* Tournament Menu buttons Container */

/* Tournament Menu button */
.TourBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  background: var(--nav-btn);
  background-size: 250%;
  background-position: left;
  color: var(--main-nav);
  margin: .5rem;
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
  position: relative;
}

@media (max-width: 480px) {
  
  .TourBtn {
    width: 88px;
    height: 32px;
    margin: .1rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .TourBtn {
    width: 128px;
    height: 36px;
    margin: .1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .TourBtn {
    width: 140px;
    height: 40px;
    margin: .5rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .TourBtn {
    width: 140px;
    height: 40px;
    margin: .5rem;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .TourBtn {
    width: 140px;
    height: 40px;
    margin: .5rem;
  }
}

@media (min-width: 1600px) {
  
  .TourBtn {
    width: 140px;
    height: 40px;
    margin: .5rem;
  }
}

.TourBtn::before {
  content: " " attr(data) " ";
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-nav);
  width: 97%;
  height: 90%;
  border-radius: 8px;
  background-color: var(--forth-nav);
  background-size: 200%;
  font: bold .74rem 'Raleway', sans-serif;
  transition-duration: 1s;
  position: absolute;
}

@media (max-width: 480px) {
  
  .TourBtn::before {
    font: bold .6rem 'Raleway', sans-serif;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .TourBtn::before {
    font: bold .68rem 'Raleway', sans-serif;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .TourBtn::before {
    font: bold .74rem 'Raleway', sans-serif;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .TourBtn::before {
    font: bold .74rem 'Raleway', sans-serif;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .TourBtn::before {
    font: bold .74rem 'Raleway', sans-serif;
  }
}

@media (min-width: 1600px) {
  
  .TourBtn::before {
    font: bold .74rem 'Raleway', sans-serif;
  }
}

.TourBtn:hover {
  background-position: right;
  transition-duration: 1s;
}

.TourBtn:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.TourBtn:active {
  transform: scale(0.95);
}
/* Tournament Menu button */