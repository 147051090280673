/* General Animation */

.RedirectToCreatedRoom{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 468px;
  background: var(--grey-first);
  position: absolute;
  z-index: 6;
}

.RedirectToCreatedRoom-loading{
  display: flex;
  justify-content: center;
  align-items: center;
}