/* ------ All Players Watch Corner --------- */
.GameWatchingContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(116, 4, 190, 0.199);
  background-image: linear-gradient(to bottom, rgba(142, 45, 226, 0.1), rgba(16, 1, 44, 0.6));
  position: absolute;
}

/* - Loader - */
.GameWatchingContainer-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: hide 2.4s forwards;
  z-index: 1;
}
/* - Loader - */

/* in room buttons , settings and exit */
.GameWatchingContainer-btn-settingsAndExit{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  height: 106px;
  position: absolute;
  bottom: 28px;
  right: 4px;
}

.GameWatchingContainer-btn-settings{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgba(2, 42, 68, 0.7);
  position: absolute;
  bottom: 60px;
  right: 0;
}

.GameWatchingContainer-btn-exit{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgba(21, 0, 0, .7);
  position: absolute;
  bottom: 0;
  right: 0;
}

/* plus sign */
.GameWatchingContainer-btn-exit-sign, .GameWatchingContainer-btn-settings-sign{
  width: 100%;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.GameWatchingContainer-btn-exit-sign svg, .GameWatchingContainer-btn-settings-sign svg {
  width: 18px;
}

.GameWatchingContainer-btn-exit-sign svg path, .GameWatchingContainer-btn-settings-sign svg path {
  fill: white;
}
/* text */
        
.GameWatchingContainer-btn-exit-text, .GameWatchingContainer-btn-settings-text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: .8rem;
  font-weight: 600;
  transition-duration: .3s;
  pointer-events: none;
}

@media (min-width: 992px) {
  /* hover effect on button width */
  .GameWatchingContainer-btn-exit:hover, .GameWatchingContainer-btn-settings:hover {
    width: 125px;
    border-radius: 40px;
    transition-duration: .3s;
  }

  .GameWatchingContainer-btn-exit:hover .GameWatchingContainer-btn-exit-sign,  .GameWatchingContainer-btn-settings:hover .GameWatchingContainer-btn-settings-sign{
    width: 30%;
    transition-duration: .3s;
    padding-left: 20px;
  }

  /* hover effect button's text */
  .GameWatchingContainer-btn-exit:hover .GameWatchingContainer-btn-exit-text, .GameWatchingContainer-btn-settings:hover .GameWatchingContainer-btn-settings-text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
  }
}

/* button click effect*/
.GameWatchingContainer-btn-exit:active, .GameWatchingContainer-btn-settings:active {
  transform: translate(2px ,2px);
}

/* in room buttons , exit */