#hamburger_button {
  width: 39px;
  overflow: hidden;
}

#hamburger_checkbox {
  display: none;
}

#hamburger_label {
  position: relative;
  display: block;
  height: 29px;
  cursor: pointer;
}

#hamburger_label:before,
#hamburger_label:after,
#hamburger_text_bar {
  position: absolute;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--main-color-third);
}

#hamburger_label:before,
#hamburger_label:after {
  content: "";
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) left;
}

#hamburger_label:before {
  top: 0;
}

#hamburger_label:after {
  top: 12px;
}

#hamburger_text_bar {
  top: 24px;
}

#hamburger_text_bar:before {
  content: "MENU";
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  color: var(--main-color-third);
  font-size: 12px;
  font-weight: bold;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  text-align: center;
}

#hamburger_checkbox:checked + #hamburger_label:before {
  left: -39px;
}

#hamburger_checkbox:checked + #hamburger_label:after {
  left: 39px;
}

#hamburger_checkbox:checked + #hamburger_label #hamburger_text_bar:before {
  animation: moveUpThenDown 0.8s ease 0.2s forwards,
    shakeWhileMovingUp 0.8s ease 0.2s forwards,
    shakeWhileMovingDown 0.2s ease 0.8s forwards;
}