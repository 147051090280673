@keyframes moveGameWatcherScoreAreaDot{

  0%,
  100% {
    top: 15%;
    right: 10%;
  }
  25% {
    top: 15%;
    right: calc(90% - 4px);
  }
  50% {
    top: calc(90% - 4px);
    right: calc(90% - 4px);
  }
  75% {
    top: calc(90% - 4px);
    right: 10%;
  }
  
}


