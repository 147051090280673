/* Create Room */
.CreateRoomFormContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 468px;
  position: absolute;
  z-index: 5;
}

.CreateRoom{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--grey);  
  opacity: 1;
  padding: 1.8rem .2rem .3rem;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  animation: show .4s forwards, CreateRoomEvent 1.4s forwards, CreateRoomShow 1.1s forwards;
}
/* Create Room */

/* Create Room Radio buttons */
.CreateRoom-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 50px;
  margin-top: 1rem;
  gap: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid var(--grey-light);
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow-feature-items);
  position: relative;
}

.CreateRoom-button-container-passcode{
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 50px;
  margin-top: 1rem;
  gap: 1rem;
  position: relative;
}

.CreateRoom-button-container h3{
  font: var(--signin-form-fifth);
  color: var(--white-first);
  position: absolute;
  top: -36px;
}

.CreateRoom-button-container .passCodeErr{
  display: flex;
  justify-content: center;
  width: 320px;
  height: 22px;
  background: var(--grey);
  color: var(--off-red);
}

.CreateRoom-button {
  display: flex;
  position: relative;
  cursor: pointer;
}

.CreateRoom-button__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.CreateRoom-button__input_passcode{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 20px;
  font-size: 1rem;
  background: transparent;
  color: var(--white-first);
  position: absolute;
  z-index: 5;
}

.CreateRoom-button__label {
  display: flex;
  padding-left: 1.2rem;
  margin-bottom: .6rem;
  position: relative;
  font-size: .82rem;
  color: var(--white-second);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.CreateRoom-button__custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--brownish);
  transition: all 0.3s ease;
}

.CreateRoom-button__input:checked + .CreateRoom-button__label .CreateRoom-button__custom {
  background-color: var(--main-wave);
  border-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 20px var(--main-color);
}

.CreateRoom-button__input:checked + .CreateRoom-button__label {
  color: var(--main-color);
}

.CreateRoom-button__label:hover .CreateRoom-button__custom {
  transform: scale(1.2);
  border-color: var(--main-color);
  box-shadow: 0 0 20px var(--main-wave);
}
/* Create Room Radio buttons */

