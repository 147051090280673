.General{
  display: flex;
  justify-items: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 5;
}

@media (max-width: 480px) {
  
  .General{
    min-width: 350px;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .General{
    width: 100vw;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .General{
    width: 100%;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .General{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .General{
    width: 800px;
  }
}

@media (min-width: 1600px) {
  
  .General{
    width: 800px;
  }
}