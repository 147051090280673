.delete-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 130px;
  background: var(--forth-nav-trans);
  opacity: 0;
  border: 6px solid var(--main-wave);
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  animation: show .3s forwards;
}

.delete-container::before{
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 338px;
  height: 148px;
  background: var(--forth-nav-trans);
  box-shadow: var(--box-shadow-signin);
  border-radius: .4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.delete-container::after{
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: .4rem;
  background: rgba(1, 16, 48, 0.88);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
}

.delete-container h2 {
  font: var(--avatar-table);
  color: var(--white-second);
  padding: 0.5rem 1rem;
  text-shadow: var(--text-shadow-simple);
}

.delete-container_Btn_Place{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

.delete-container_Btn_Place_button {
  background-color: transparent;
  color: var(--main-wave);
  width: 10rem;
  height: 2.6rem;
  border: 0.2rem solid transparent;
  border-radius: .3rem;
  margin: 0 1rem;
  text-align: center;
  transition: all 0.6s ease;
  cursor: pointer;
}

.delete-container_Btn_Place_button:hover {
  background-color: black;
  color: var(--main-nav);
}

.delete-container_Btn_Place_text {
  pointer-events: none;
  margin: 0 1rem
}

.DeleteCompletedMessage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 60px;
  opacity: 0;
  background: var(--grey); 
  border: 6px solid var(--main-wave);
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  top: 60px;
  left: 50%;
  animation: show .8s forwards, hide 3.2s forwards;
  transform: translate(-50%, -40%);
  z-index: 5;
}

.DeleteCompletedMessage h2 {
  font: var(--avatar-table);
  color: var(--white-second);
  padding: 0.5rem 1rem;
  text-shadow: var(--text-shadow-simple);
}