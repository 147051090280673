/* Footer main container */
.Footer{
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  grid-row: 3 / 4;
  height: 100%;
  position: relative;
  z-index: 3;
}

@media (max-width: 480px) {
  
  .Footer{
    min-width: 350px;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .Footer{
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .Footer{
    width: 100vw;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .Footer{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .Footer{
    width: 800px;
  }
}

@media (min-width: 1600px) {
  
  .Footer{
    width: 800px;
  }
}

.Footer::after{
  content: "";
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

.footer_top_left{
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  position: relative;
  z-index: 5;
}

.footer_top_center{
  display: flex;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.footer_top_right{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  position: relative;
  z-index: 5;
}

.footer_bottom_center{
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 4;
  grid-row: 2 / 3;
}

.copyRight{
  letter-spacing: .02em;
  font-weight: 400;
  font-size: 0.7rem;
  font-family: 'Raleway', sans-serif;
  text-transform: Uppercase;
  background: var(--grey-first);
  text-align: center;
  text-decoration: none;
  padding: .4rem 2rem;
  clip-path: var(--hexagon-secondary);
  box-shadow: var(--box-shadow-menu);
  position: relative;
  z-index: 5;
}

.copyRight > a:link, a:visited{
  color: var(--brown);
  text-decoration: none;
}

.copyRight > a:hover{
  color: var(--secondary-color-second);
}

.copyRight > a:active {
  color: var(--main-color-third);
}
/* Footer main container */