/* In Multi Room First Container Area */
.InMultiRoomArea{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (max-width: 480px) {
  
  .InMultiRoomArea{
    min-width: 350px;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .InMultiRoomArea{
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .InMultiRoomArea{
    width: 100%;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .InMultiRoomArea{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .InMultiRoomArea{
    width: 800px;
  }
}

@media (min-width: 1600px) {
  
  .InMultiRoomArea{
    width: 800px;
  }
}
/* In Multi Room First Container Area */