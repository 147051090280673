/* ---------------- Circle ----------------------------- */
.RoomGameNumbers{
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  position: absolute;
  z-index: 5;
}

@media (max-width: 480px) {
  
  .RoomGameNumbers{
    width: 100%;
    min-width: 350px;
    height: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .RoomGameNumbers{
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .RoomGameNumbers{
    width: 100%;
    max-width: 800px;
    height: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .RoomGameNumbers{
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .RoomGameNumbers{
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1600px) {
  
  .RoomGameNumbers{
    width: 100%;
    height: 100%;
  }
}

.circle-numbers-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--box-shadow-signin);
  background: var(--grey-simple);
  opacity: 1;
  border-radius: .8rem;
  position: absolute;
}

@media (max-width: 480px) {
  
  .circle-numbers-container{
    width: calc(100% / 1.14);
    max-width: 420px;
    min-width: 350px;
    height: 360px;
    bottom: 80px;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .circle-numbers-container{
    width: 420px;
    height: 360px;
    bottom: 70px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .circle-numbers-container{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .circle-numbers-container{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
 
  .circle-numbers-container{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .circle-numbers-container{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 1600px) {
  
  .circle-numbers-container{
    width: 420px;
    height: 420px;
  }
}

.circle-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, .6), 0 2px 20px rgba(255, 255, 255, 1);
  background: var(--grey-first);
  opacity: 0;
  position: relative;
  overflow: hidden;
  animation: show .2s forwards;
}

@media (max-width: 480px) {
  
  .circle-numbers{
    width: 350px;
    height: 350px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .circle-numbers{
    width: 360px;
    height: 360px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .circle-numbers{
    width: 360px;
    height: 360px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .circle-numbers{
    width: 360px;
    height: 360px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .circle-numbers{
    width: 360px;
    height: 360px;
  }
}

@media (min-width: 1600px) {
  
  .circle-numbers{
    width: 360px;
    height: 360px;
  }
}

.circle-numbers-center {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  box-shadow: inset 0 2px 2px rgba(255, 255, 255, .4), 0 3px 13px rgba(0, 0, 0, .85);
  background: var(--main-wave);
  position: relative;
}

.circle-numbers-center-small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  box-shadow: inset 0 2px 3px rgba(255, 255, 255, .6), 0 8px 20px rgba(0, 0, 0, .9);
  background: var(--brownish);
}

.circle-numbers-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.RoomGameNumbers-selected-light {
  z-index: 1;
  width: 180px;
  height: 116px;
  background: var(--secondary-color-first);
  clip-path: polygon(100% 100%, 100% 0, 0 50%);
  box-shadow: var(--box-shadow-signin);
  margin-top: -58px;
  transform-origin: 0% 50%;
  transform: rotate(-90deg);
  transition: all .5s;
  
}

.RoomGameNumbers-selected-dot {
  z-index: 6;
  width: 80px;
  height: 20px;
  margin-top: -10px;
  transform-origin: 0% 50%;
  transform: rotate(-90deg);
  transition: all .5s;
  background: transparent;
}

.RoomGameNumbers-selected-dot span {
  position: absolute;
  top: 0;
  left: 74px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: var(--white-first);
}

.RoomGameNumbers-selected-light, .RoomGameNumbers-selected-dot {
  position: absolute;
  left: 50%;
  top: 50%;
}

.circle-numbers-switch label {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 110px;
  margin-top: -55px;
  transform-origin: 0% 50%;
  clip-path: polygon(100% 100%, 100% 0, 0 50%);
  cursor: pointer;
  animation: roomGameNumbersLightChanger 10s infinite;
}

.circle-numbers-switch label:hover{
  z-index: 3;
  background: rgba(0, 0, 0, .4);
}

/* Numbers */
.circle-numbers-switch label span {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  font: var(--signin-submit);
  line-height: 100px;
  text-align: center;
  color: #eee;
  text-shadow: var(--text-shadow-simple);
}
/* Numbers */

/* Number 0 */
.circle-numbers-switch label:nth-child(1) {
  transform: rotate(-90deg);
  animation-delay: 0s;
}

.circle-numbers-switch label:nth-child(1) span {
  transform: rotate(90deg);
}
/* Number 0 */

/* Number 1 */
.circle-numbers-switch label:nth-child(2) {
  transform: rotate(-54deg);
  animation-delay: 1s;
}

.circle-numbers-switch label:nth-child(2) span {
  transform: rotate(54deg);
}
/* Number 1 */

/* Number 2 */
.circle-numbers-switch label:nth-child(3) {
  transform: rotate(-18deg);
  animation-delay: 2s;
}

.circle-numbers-switch label:nth-child(3) span {
  transform: rotate(18deg);
}
/* Number 2 */

/* Number 3 */
.circle-numbers-switch label:nth-child(4) {
  transform: rotate(18deg);
  animation-delay: 3s;
}

.circle-numbers-switch label:nth-child(4) span {
  transform: rotate(-18deg);
}
/* Number 3 */

/* Number 4 */
.circle-numbers-switch label:nth-child(5) {
  transform: rotate(54deg);
  animation-delay: 4s;
}

.circle-numbers-switch label:nth-child(5) span {
  transform: rotate(-54deg);
}
/* Number 4 */

/* Number 5 */
.circle-numbers-switch label:nth-child(6) {
  transform: rotate(90deg);
  animation-delay: 5s;
}

.circle-numbers-switch label:nth-child(6) span {
  transform: rotate(-90deg);
}
/* Number 5 */

/* Number 6 */
.circle-numbers-switch label:nth-child(7) {
  transform: rotate(126deg);
  animation-delay: 6s;
}

.circle-numbers-switch label:nth-child(7) span {
  transform: rotate(-126deg);
}
/* Number 6 */

/* Number 7 */
.circle-numbers-switch label:nth-child(8) {
  transform: rotate(162deg);
  animation-delay: 7s;
}

.circle-numbers-switch label:nth-child(8) span {
  transform: rotate(-162deg);
}
/* Number 7 */

/* Number 8 */
.circle-numbers-switch label:nth-child(9) {
  transform: rotate(198deg);
  animation-delay: 8s;
}

.circle-numbers-switch label:nth-child(9) span {
  transform: rotate(-198deg);
}
/* Number 8 */

/* Number 9 */
.circle-numbers-switch label:nth-child(10) {
  transform: rotate(234deg);
  animation-delay: 9s;
}

.circle-numbers-switch label:nth-child(10) span {
  transform: rotate(-234deg);
}
/* Number 9 */

 .circle-numbers-switch input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

/* SWITCH LIGHT */

/* SWITCH LIGHT POSITION */

#RoomGameNumberslineswitch_0:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(-90deg);
}

#RoomGameNumberslineswitch_1:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(-54deg);
}

#RoomGameNumberslineswitch_2:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(-18deg);
}

#RoomGameNumberslineswitch_3:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(18deg);
}

#RoomGameNumberslineswitch_4:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(54deg);
}

#RoomGameNumberslineswitch_5:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(90deg);
}

#RoomGameNumberslineswitch_6:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(126deg);
}

#RoomGameNumberslineswitch_7:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(162deg);
}

#RoomGameNumberslineswitch_8:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(198deg);
}

#RoomGameNumberslineswitch_9:checked ~ .RoomGameNumbers-selected-light {
  transform: rotate(234deg);
}

/* SWITCH LIGHT */

/* SWITCH LIGHT POSITION */

#RoomGameNumberslineswitch_0:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(-90deg);
}

#RoomGameNumberslineswitch_0:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(90deg);
}

#RoomGameNumberslineswitch_1:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(-54deg);
}

#RoomGameNumberslineswitch_1:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(54deg);
}

#RoomGameNumberslineswitch_2:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(-18deg);
}

#RoomGameNumberslineswitch_2:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(18deg);
}

#RoomGameNumberslineswitch_3:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(18deg);
}

#RoomGameNumberslineswitch_3:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(-18deg);
}

#RoomGameNumberslineswitch_4:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(54deg);
}

#RoomGameNumberslineswitch_4:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(-54deg);
}

#RoomGameNumberslineswitch_5:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(90deg);
}

#RoomGameNumberslineswitch_5:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(-90deg);
}

#RoomGameNumberslineswitch_6:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(126deg);
}

#RoomGameNumberslineswitch_6:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(-126deg);
}

#RoomGameNumberslineswitch_7:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(162deg);
}

#RoomGameNumberslineswitch_7:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(-162deg);
}

#RoomGameNumberslineswitch_8:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(198deg);
}

#RoomGameNumberslineswitch_8:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(-198deg);
}

#RoomGameNumberslineswitch_9:checked ~ .RoomGameNumbers-selected-dot {
  transform: rotate(234deg);
}

#RoomGameNumberslineswitch_9:checked ~ .RoomGameNumbers-selected-dot span {
  transform: rotate(-234deg);
}

/* LINE */

.RoomGameNumbersline {
  z-index: 1;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 0;
  margin-top: -1px;
  border-width: 1px 0;
  border-style: solid;
  border-top-color: #3c3d3f;
  border-bottom-color: #666769;
}

.RoomGameNumbersline:nth-child(1) {
  transform: rotate(-72deg);
}

.RoomGameNumbersline:nth-child(2) {
  transform: rotate(72deg);
}

.RoomGameNumbersline:nth-child(3) {
  transform: rotate(36deg);
}

.RoomGameNumbersline:nth-child(4) {
  transform: rotate(-36deg);
}
/* ---------------- Circle ----------------------------- */

/* ---------------- Loader ----------------------------- */
.RoomGameNumbers-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  animation: roomGameNumbersLayerBack 1s 4.6s forwards;
  z-index: 4;
}
/* ---------------- Loader ----------------------------- */

/* ---------------- Milestones ----------------------------- */
.RoomGameNumbersFeaturesContainer{
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
  box-shadow: var(--box-shadow-signin);
  background: rgba(21, 0, 0, .3);
  position: absolute;
  animation: show 3.2s forwards;
  z-index: 1;
}

.RoomGameNumbersFeaturesContainer::before{
  content: "Milestones";
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 98px;
  height: 32px;
  font: var(--signin-form-forth);
  text-shadow: var(--text-shadow-simple);
  color: var(--white-first);
  border-radius: .8rem;
  position: absolute;
}

@media (max-width: 480px) {
  
  .RoomGameNumbersFeaturesContainer{
    flex-direction: row;
    width: 280px;
    height: 48px;
    border-radius: .4rem;
    bottom: 452px;
  }

  .RoomGameNumbersFeaturesContainer::before{
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .RoomGameNumbersFeaturesContainer{
    flex-direction: row;
    width: 320px;
    height: 48px;
    border-radius: .4rem;
    bottom: 444px;
  }

  .RoomGameNumbersFeaturesContainer::before{
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .RoomGameNumbersFeaturesContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    border-radius: .8rem;
    left: 20px;
  }

  .RoomGameNumbersFeaturesContainer::before{
    bottom: 310px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .RoomGameNumbersFeaturesContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    border-radius: .8rem;
    left: 20px;
  }

  .RoomGameNumbersFeaturesContainer::before{
    bottom: 310px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .RoomGameNumbersFeaturesContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    border-radius: .8rem;
    left: 20px;
  }

  .RoomGameNumbersFeaturesContainer::before{
    bottom: 310px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .RoomGameNumbersFeaturesContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    border-radius: .8rem;
    left: 20px;
  }

  .RoomGameNumbersFeaturesContainer::before{
    bottom: 310px;
  }
}

@media (min-width: 1600px) {
  
  .RoomGameNumbersFeaturesContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    border-radius: .8rem;
    left: 20px;
  }

  .RoomGameNumbersFeaturesContainer::before{
    bottom: 310px;
  }
}

.RoomGameNumbersFeatures{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  box-shadow: var(--box-shadow-signin);
  background: var(--brownish-trans);
  border-radius: 1.2rem;
}
/* ---------------- Milestones ----------------------------- */

/* ---------------- Support Oasis ----------------------------- */
.RoomGameNumbersHelpContainer{
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
  box-shadow: var(--box-shadow-signin);
  background: rgba(21, 0, 0, .3);
  position: absolute;
  animation: show 3.2s forwards;
  z-index: 1;
}

.RoomGameNumbersHelpContainer::before{
  content: "Support Oasis";
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 98px;
  height: 32px;
  font: var(--signin-form-forth);
  text-shadow: var(--text-shadow-simple);
  color: var(--white-first);
  border-radius: .8rem;
  position: absolute;
}

@media (max-width: 480px) {
  
  .RoomGameNumbersHelpContainer{
    flex-direction: row;
    width: calc(100% / 1.14);
    max-width: 420px;
    min-width: 350px;
    height: 72px;
    border-radius: .4rem;
    bottom: 4px;
  }

  .RoomGameNumbersHelpContainer::before{
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .RoomGameNumbersHelpContainer{
    flex-direction: row;
    width: 420px;
    height: 62px;
    border-radius: .4rem;
    bottom: 4px;
  }

  .RoomGameNumbersHelpContainer::before{
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .RoomGameNumbersHelpContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    border-radius: .8rem;
    right: 20px;
  }

  .RoomGameNumbersHelpContainer::before{
    bottom: 310px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .RoomGameNumbersHelpContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    border-radius: .8rem;
    right: 20px;
  }

  .RoomGameNumbersHelpContainer::before{
    bottom: 310px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .RoomGameNumbersHelpContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    right: 20px;
  }

  .RoomGameNumbersHelpContainer::before{
    bottom: 310px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .RoomGameNumbersHelpContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    right: 20px;
  }

  .RoomGameNumbersHelpContainer::before{
    bottom: 310px;
  }
}

@media (min-width: 1600px) {
  
  .RoomGameNumbersHelpContainer{
    flex-direction: column;
    width: 80px;
    height: 280px;
    right: 20px;
  }

  .RoomGameNumbersHelpContainer::before{
    bottom: 310px;
  }
}

.RoomGameNumbersHelp{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  box-shadow: var(--box-shadow-feature-items);
  background: var(--forth-nav);
  border-radius: .2rem;
  position: relative;
}
/* ---------------- Support Oasis ----------------------------- */

/* in room buttons , settings and exit */
.RoomGameNumbers-btn-settingsAndExit{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  position: absolute;
  bottom: 28px;
  right: 4px;
  z-index: 6;
}

@media (max-width: 480px) {
  
  .RoomGameNumbers-btn-settingsAndExit{
    height: 70px;
    top: 2px;
    right: 8px;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .RoomGameNumbers-btn-settingsAndExit{
    height: 70px;
    top:2px;
    right: 8px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .RoomGameNumbers-btn-settingsAndExit{
    height: 84px;
    bottom: 28px;
    right: 8px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .RoomGameNumbers-btn-settingsAndExit{
    height: 106px;
    bottom: 28px;
    right: 8px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .RoomGameNumbers-btn-settingsAndExit{
    height: 106px;
    bottom: 28px;
    right: 8px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .RoomGameNumbers-btn-settingsAndExit{
    height: 106px;
    bottom: 28px;
    right: 8px;
  }
}

@media (min-width: 1600px) {
  
  .RoomGameNumbers-btn-settingsAndExit{
    height: 106px;
    bottom: 28px;
    right: 8px;
  }
}

.RoomGameNumbers-btn-settings{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgba(2, 42, 68, 0.7);
  position: absolute;
  right: 0;
  z-index: 1;
}

.RoomGameNumbers-btn-exit{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgba(21, 0, 0, .7);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

@media (max-width: 480px) {
  
  .RoomGameNumbers-btn-settings{
    width: 28px;
    height: 28px;
    bottom: 34px;
  }
  
  .RoomGameNumbers-btn-exit{
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .RoomGameNumbers-btn-settings{
    width: 28px;
    height: 28px;
    bottom: 34px;
  }
  
  .RoomGameNumbers-btn-exit{
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .RoomGameNumbers-btn-settings{
    width: 32px;
    height: 32px;
    bottom: 42px;
  }
  
  .RoomGameNumbers-btn-exit{
    width: 32px;
    height: 32px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
 
  .RoomGameNumbers-btn-settings{
    width: 40px;
    height: 40px;
    bottom: 60px;
  }
  
  .RoomGameNumbers-btn-exit{
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .RoomGameNumbers-btn-settings{
    width: 40px;
    height: 40px;
    bottom: 60px;
  }
  
  .RoomGameNumbers-btn-exit{
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .RoomGameNumbers-btn-settings{
    width: 40px;
    height: 40px;
    bottom: 60px;
  }
  
  .RoomGameNumbers-btn-exit{
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1600px) {

  .RoomGameNumbers-btn-settings{
    width: 40px;
    height: 40px;
    bottom: 60px;
  }
  
  .RoomGameNumbers-btn-exit{
    width: 40px;
    height: 40px;
  }
}

/* plus sign */
.RoomGameNumbers-btn-exit-sign, .RoomGameNumbers-btn-settings-sign{
  width: 100%;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.RoomGameNumbers-btn-exit-sign svg, .RoomGameNumbers-btn-settings-sign svg {
  width: 18px;
}

.RoomGameNumbers-btn-exit-sign svg path, .RoomGameNumbers-btn-settings-sign svg path {
  fill: white;
}
/* text */
        
.RoomGameNumbers-btn-exit-text, .RoomGameNumbers-btn-settings-text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: .8rem;
  font-weight: 600;
  transition-duration: .3s;
  pointer-events: none;
}

@media (min-width: 992px) {
  /* hover effect on button width */
  .RoomGameNumbers-btn-exit:hover, .RoomGameNumbers-btn-settings:hover {
    width: 125px;
    border-radius: 40px;
    transition-duration: .3s;
  }

  .RoomGameNumbers-btn-exit:hover .RoomGameNumbers-btn-exit-sign,  .RoomGameNumbers-btn-settings:hover .RoomGameNumbers-btn-settings-sign{
    width: 30%;
    transition-duration: .3s;
    padding-left: 20px;
  }

  /* hover effect button's text */
  .RoomGameNumbers-btn-exit:hover .RoomGameNumbers-btn-exit-text, .RoomGameNumbers-btn-settings:hover .RoomGameNumbers-btn-settings-text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
  }
}

/* button click effect*/
.RoomGameNumbers-btn-exit:active, .RoomGameNumbers-btn-settings:active {
  transform: translate(2px ,2px);
}

/* in room buttons , exit */