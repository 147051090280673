.completed_successfuly{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  min-width: 300px;
  height: 280px;
  min-height: 280px;
  background: var(--grey-simple-dark); 
  opacity: 0;
  padding: 30px;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  cursor: default;
  position: absolute;
  animation: show .1s forwards, logInCompleted_successfuly .4s .1s forwards;
  z-index: 5;
}

.completed_successfuly-editting{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 312px;
  height: 248px;
  background: var(--grey-simple);  
  opacity: 1;
  padding: 40px;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  z-index: 5;
}

.completed_successfuly-checked{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  opacity: 0;
  animation: show .1s forwards;
}

.completed_successfuly-checked-editting{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  opacity: 0;
  animation: show 1s forwards;
}

.completed_successfuly_checked_img{
  width: 40px;
}

.completed_successfuly_loading{
  display: flex;
  width: auto;
  height: auto;
}

.completed_successfuly_loading-editting{
  display: flex;
  width: auto;
  height: auto;
  opacity: 1;
  animation: hide .8s forwards;
}

.completed_successfuly_message{
  display: flex;
  width: auto;
  height: auto;
  font: var(--avatar-table);
  color: var(--white-second);
  opacity: 0;
  animation: show .1s forwards;
}

.completed_successfuly_message-editting{
  display: flex;
  width: auto;
  height: auto;
  font: var(--avatar-table);
  color: var(--white-second);
  opacity: 0;
  animation: show 1s forwards;
}


.completed_successfuly_btn{
  display: flex;
  width: 80%;
  height: auto;
  position: relative; 
  opacity: 0;
  animation: show .1s forwards;
}

.completed_successfuly_btn-editting{
  display: flex;
  width: 80%;
  height: auto;
  position: relative; 
  opacity: 0;
  animation: show 1s forwards;
}