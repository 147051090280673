@keyframes blob-bounce {
  0% {
    transform: translate(-40%, -40%);
  }

  25% {
    transform: translate(40%, -40%);
  }

  40% {
    transform: translate(40%, 10%);
  }

  75% {
    transform: translate(-40%, 10%);
  }

  100% {
    transform: translate(-40%, -40%);
  }
}