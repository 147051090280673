/* Header main container */
.HeaderGeneral{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  grid-row: 1 / 2;
  height: 100%;
  align-items: center;
  position: relative;
  z-index: 5;
}

@media (max-width: 480px) {
  
  .HeaderGeneral{
    min-width: 350px;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .HeaderGeneral{
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .HeaderGeneral{
    width: 100vw;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .HeaderGeneral{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {

  .HeaderGeneral{
    width: 800px;
  }
}

@media (min-width: 1600px) {
  
  .HeaderGeneral{
    width: 800px;
  }
}
/* Header main container */

.HeaderGeneral_left{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.HeaderGeneral_left_hamburgerPlace{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 50px;
  position: absolute;
  top: 20px;
}

/* Header logo place */
.HeaderGeneral_logo_place{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  animation: goldenLogoBgr 3s alternate ease-out infinite;
}

.HeaderGeneral_logo_place_mainLogo_general{
  width: 100%;
}
/* Header logo place */

/* Header right side container */
.HeaderGeneral_right{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
/* Header right side container */

/* Header hamburger container */
.HeaderGeneral_right_hamburgerPlace{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  height: 50px;
  position: absolute;
  top: 20px;
}
/* Header hamburger container */

/* Header user place container */
.HeaderGeneral_right_loginPlace{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 80%;
  height: 50px;
  position: absolute;
  bottom: 36px;
}
/* Header user place container */

/* --------------- Header Game container --------------- */

.HeaderGameContainer{
  display: flex;
  justify-content: center;
  align-items: self-start;
  height: 100%;
  position: relative;
  z-index: 5;
}

@media (max-width: 480px) {
  
  .HeaderGameContainer{
    min-width: 350px;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .HeaderGameContainer{
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .HeaderGameContainer{
    width: 100vw;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .HeaderGameContainer{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {

  .HeaderGameContainer{
    width: 800px;
  }
}

@media (min-width: 1600px) {
  
  .HeaderGameContainer{
    width: 800px;
  }
}

.HeaderGameRegular{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;

}

.HeaderGameShort{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  position: relative;
  z-index: 5;
}

.HeaderGame-player1-container, .HeaderGame-player2-container, .HeaderGame-player3-container, .HeaderGame-player4-container {
  background-image: linear-gradient(163deg, var(--main-wave) 0%, var(--main-color) 100%);
  box-shadow: 0px 0px 30px 1px var(--main-color-third-trans);
  margin: .4rem .6rem;
  transition: all .3s;
  cursor: pointer;
}

.HeaderGame-player {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  transition: all .2s;
  position: relative;
  pointer-events: none;
}

.HeaderGame-player:hover {
  transform: scale(0.98);
  border-radius: 1rem;
}

.HeaderGame-player-active {
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(45deg, var(--main-wave) 25%, var(--main-wave) 25%, var(--main-color-first) 25%, var(--main-color-first) 50%, var(--main-wave) 50%, var(--main-wave) 75%, var(--main-color-first) 75%, var(--main-color-first) 100%);
  transition: .3s;
  position: absolute;
  overflow: visible;
  animation: activePlayersTurn 28s linear infinite;
  z-index: -1;
  pointer-events: none;
}

.HeaderGame-player-x{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  pointer-events: none;
}

@media (max-height: 799px){
 
  .HeaderGame-player1-container, .HeaderGame-player2-container, .HeaderGame-player3-container, .HeaderGame-player4-container{
    width: calc(100% / 6);
    min-width: 58px;
    height: 36px;
  }

  .HeaderGame-player {
    border-radius: .1rem;
  }

  .HeaderGame-player:hover {
    border-radius: .1rem;
  }

  .HeaderGame-player-active{
    border-radius: .1rem;
  }
}

@media (max-width: 480px) and (min-height: 800px){
 
  .HeaderGame-player1-container, .HeaderGame-player2-container, .HeaderGame-player3-container, .HeaderGame-player4-container{
    width: calc(100% / 6);
    min-width: 58px;
    height: calc(100% - 64px);
    max-height: 180px;
  }

  .HeaderGame-player {
    border-radius: .1rem;
  }

  .HeaderGame-player:hover {
    border-radius: .1rem;
  }

  .HeaderGame-player-active{
    border-radius: .1rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) and (min-height: 800px){
  
  .HeaderGame-player1-container, .HeaderGame-player2-container, .HeaderGame-player3-container, .HeaderGame-player4-container{
    width: calc(100% / 6);
    height: calc(100% - 58px);
    max-height: 180px;
  }

  .HeaderGame-player {
    border-radius: .1rem;
  }

  .HeaderGame-player:hover {
    border-radius: .1rem;
  }

  .HeaderGame-player-active{
    border-radius: .1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-height: 800px){
  
  .HeaderGame-player1-container, .HeaderGame-player2-container, .HeaderGame-player3-container, .HeaderGame-player4-container{
    width: calc(100% / 8);
    max-width: 110px;
    height: calc(100% - 44px);
    max-height: 180px;
  }

  .HeaderGame-player {
    border-radius: .1rem;
  }

  .HeaderGame-player:hover {
    border-radius: .1rem;
  }

  .HeaderGame-player-active{
    border-radius: .1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-height: 800px){
  
  .HeaderGame-player1-container, .HeaderGame-player2-container, .HeaderGame-player3-container, .HeaderGame-player4-container{
    width: 110px;
    height: calc(100% - 38px);
    max-height: 180px;
  }

  .HeaderGame-player {
    border-radius: .1rem;
  }

  .HeaderGame-player:hover {
    border-radius: .1rem;
  }

  .HeaderGame-player-active{
    border-radius: .1rem;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) and (min-height: 800px){
 
  .HeaderGame-player1-container, .HeaderGame-player2-container, .HeaderGame-player3-container, .HeaderGame-player4-container{
    width: 110px;
    height: calc(100% - 38px);
    max-height: 180px;
  }

  .HeaderGame-player {
    border-radius: .1rem;
  }

  .HeaderGame-player:hover {
    border-radius: .1rem;
  }

  .HeaderGame-player-active{
    border-radius: .1rem;
  }
}

@media (min-width: 1600px) and (min-height: 800px){
  
  .HeaderGame-player1-container, .HeaderGame-player2-container, .HeaderGame-player3-container, .HeaderGame-player4-container{
    width: 110px;
    height: calc(100% - 38px);
    max-height: 180px;
  }

  .HeaderGame-player {
    border-radius: .1rem;
  }

  .HeaderGame-player:hover {
    border-radius: .1rem;
  }

  .HeaderGame-player-active{
    border-radius: .1rem;
  }
}

@media (max-height: 799px) {
  
  .HeaderGame-player-x-logo{
    display: none;
  }
}

@media (min-height: 800px) {
  
  .HeaderGame-player-x-logo{
    width: calc(100% - 8px);
  }
}

.HeaderGame-player-Title{
  display: flex;
  justify-content: center;
  color: var(--main-color-second);
  text-transform: capitalize;
  position: absolute;
  cursor: default;
}

.HeaderGame-player-Title{
  width: calc(100% - 8px);
  height: 18px;
  font: bold .84rem 'Raleway', sans-serif;;
  bottom: 2px;
}

.HeaderGame-players-vs{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 38px);
}

@media (max-height: 799px){
  
  .HeaderGame-players-vs{
    width: 52px;
  }
}

@media (max-width: 480px) and (min-height: 800px){
  
  .HeaderGame-players-vs{
    width: 62px;
  }
}

@media (min-width: 481px) and (max-width: 767px) and (min-height: 800px){

  .HeaderGame-players-vs{
    width: 88px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-height: 800px){

  .HeaderGame-players-vs{
    width: 112px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-height: 800px){
  .HeaderGame-players-vs{
    width: 130px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) and (min-height: 800px){
  
  .HeaderGame-players-vs{
    width: 130px;
  }
}

@media (min-width: 1600px) and (min-height: 800px){
  
  .HeaderGame-players-vs{
    width: 130px;
  }
}

.HeaderGame-players-vs::after{
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 64px);
  border-radius: 50%;
  box-shadow: 0px 0px 30px 1px var(--main-color-third-trans);
  background: rgba(0, 0, 0, .1);
  position: absolute;
}

@media (max-height: 799px){
  
  .HeaderGame-players-vs::after{
    display: none;
  }
}

@media (max-width: 480px) and (min-height: 800px){
  
  .HeaderGame-players-vs::after{
    width: 62px;
  }
}

@media (min-width: 481px) and (max-width: 767px) and (min-height: 800px){
  
  .HeaderGame-players-vs::after{
    width: 88px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-height: 800px){
  
  .HeaderGame-players-vs::after{
    width: 112px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-height: 800px){
  
  .HeaderGame-players-vs::after{
    width: 130px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) and (min-height: 800px){
 
  .HeaderGame-players-vs::after{
    width: 130px;
  }
}

@media (min-width: 1600px) and (min-height: 800px){
 
  .HeaderGame-players-vs::after{
    width: 130px;
  }
}

.HeaderGame_logo_place_mainLogo_game{
  width: 100%;
}
