/* ------ Watching Score Area --------- */
.GameWatcherScoreArea {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: default;
  position: relative;
  animation: show 2.2s forwards;
}

@media (max-width: 480px) {
  
  .GameWatcherScoreArea {
    width: calc(100% - 14px);
    min-width: 350px;
    height: calc(100% / 2.2);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .GameWatcherScoreArea {
    width: calc(100% - 14px);
    max-width: 540px;
    height: calc(100% / 2.2);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .GameWatcherScoreArea {
    width: calc(100% / 1.48);
    max-width: 542px;
    height: calc(100% / 2.2);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .GameWatcherScoreArea {
    width: calc(100% / 1.48);
    height: calc(100% / 2.2);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .GameWatcherScoreArea {
    width: calc(100% / 1.48);
    height: calc(100% / 2.2);
  }
}

@media (min-width: 1600px) {
  
  .GameWatcherScoreArea {
    width: calc(100% / 1.48);
    height: calc(100% / 2.2);
  }
}

.GameWatcherScoreAreaOuter {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: radial-gradient(circle 230px at 0% 0%, #df4e0a, #0c0d0d);
  position: relative;
}

.GameWatcherScoreAreaDot {
  display: flex;
  width: 5px;
  height: 5px;
  background-color: var(--main-wave);
  box-shadow: 0 0 10px #e97203;
  border-radius: 100px;
  position: absolute;
  z-index: 2;
  right: 10%;
  top: 10%;
  animation: moveGameWatcherScoreAreaDot 18s linear infinite;
}

.GameWatcherScoreAreaCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  border: solid 2px var(--settings-color);
  background-size: 20px 20px;
  background: radial-gradient(circle 280px at 0% 0%, #444444, #0c0d0d);
  color: #ec6313;
  box-shadow: var(--box-shadow-signin);
  position: relative;
  z-index: 1;
}

.GameWatcherScoreAreaLight {
  width: 240px;
  height: 45px;
  border-radius: 100px;
  position: absolute;
  background-color: var(--main-wave);
  opacity: 0.4;
  box-shadow: 0 0 50px #023788;
  filter: blur(10px);
  transform-origin: 10%;
  top: 0%;
  left: 0;
  transform: rotate(30deg);
}

.GameWatcherScoreAreaScore {
  display: flex;
  font: bolder 4rem 'Raleway', sans-serif;
  background: linear-gradient(45deg, var(--main-wave) 4%, var(--main-nav), var(--main-wave));
  background-clip: text;
  color: transparent;
  text-shadow: var(--text-shadow);
}

.GameWatcherScoreAreaTimer{
  display: flex;
  font: bold .98rem 'Raleway', sans-serif;
  background: linear-gradient(45deg, var(--settings-color) 4%, var(--secondary-color), var(--settings-color));
  background-clip: text;
  color: transparent;
  text-shadow: var(--text-shadow);
  position: absolute;
  bottom: 2px;
}

.GameWatcherScoreAreaLine {
  width: 100%;
  height: 2px;
  position: absolute;
  background: var(--main-wave-trans);
}

.GameWatcherScoreAreaTop {
  top: 15%;
  background: linear-gradient(90deg, var(--main-wave-trans) 30%, var(--main-color-third) 70%);
}

.GameWatcherScoreAreaBottom {
  bottom: 10%;
}

.GameWatcherScoreAreaLeft {
  right: 90%;
  width: 1px;
  height: 100%;
  background: linear-gradient(180deg, var(--main-wave-trans-extra) 30%, #222424 70%);
}

.GameWatcherScoreAreaRight {
  right: 10%;
  width: 1px;
  height: 100%;
}
/* ------ Watching Score Area --------- */