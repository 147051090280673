.OpponentTurnPause{
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(5, 27, 224, 0.198);
  border-radius: .4rem;
  box-shadow: var(--box-shadow-features);
  position: absolute;
  animation: OpponentTurnPauseDarkness .2s 1.4s forwards;
  z-index: 2;
}

@media (max-width: 480px) {
  
  .OpponentTurnPause{
    width: 100%;
    min-width: 350px;
    height: 360px;
    bottom: 0;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .OpponentTurnPause{
    width: 420px;
    height: 360px;
    bottom: 0;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .OpponentTurnPause{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .OpponentTurnPause{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .OpponentTurnPause{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .OpponentTurnPause{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 1600px) {
  
  .OpponentTurnPause{
    width: 420px;
    height: 420px;
  }
}

/* ///// 2nd Layer /////////// */
.OpponentTurnPause-secondLayer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 420px;
  background: rgba(0, 5, 46, 0.84);
  background-image: url('../../../../../Images/greyishBgr.png');
  border-radius: .3rem;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, .6), 0 2px 20px rgba(255, 255, 255, 1);
  opacity: 0;
  position: absolute;
  animation: show .4s forwards;
  z-index: 3;
}

@media (max-width: 480px) {
  
  .OpponentTurnPause-secondLayer{
    width: 100%;
    min-width: 350px;
    height: 360px;
    bottom: 0;
  }
}

@media (min-width: 481px) and (max-width: 699px) {
  
  .OpponentTurnPause-secondLayer{
    width: 420px;
    height: 420px;
    bottom: 0px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  
  .OpponentTurnPause-secondLayer{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .OpponentTurnPause-secondLayer{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .OpponentTurnPause-secondLayer{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .OpponentTurnPause-secondLayer{
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 1600px) {
  
  .OpponentTurnPause-secondLayer{
    width: 420px;
    height: 420px;
  }
}

/* waiting */
.OpponentTurnPause-wait{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 38px;
  background: var(--black-light);
  box-shadow: var(--box-shadow-signin);
  position: absolute;
  top: -28px;
  z-index: 6;
 }

.OpponentTurnPause-wait::after{
  content: "Please wait, it's your opponent's turn";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  width: 340px;
  height: 38px;
  border-radius: .4rem;
  box-shadow: var(--box-shadow-signin);
  padding: .1rem;
  background: rgba(255, 103, 0, .4);
  color: var(--forth-nav);
  position: absolute;
  font: bolder .8rem 'Raleway', sans-serif;
  text-shadow: var(--text-shadow-extra);
}
/* waiting */

.OpponentTurnPause-secondLayer-scoreBgr{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 260px;
  background: var(--grey-first);
  border: 22px solid rgba(137, 172, 216, .14);
  border-radius: 50%;
  box-shadow: var(--box-shadow-signin);
  position: relative;
}

.OpponentTurnPause-secondLayer-scoreBgr-img{
  width: 252px;
  opacity: 0;
  position: absolute;
  animation: show .8s forwards, OpponentTurnSpinner 198s infinite;
}

.OpponentTurnPause-secondLayer-score{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
}

.OpponentTurnPause-secondLayer-score-title{
  font: bold 3.8rem 'Raleway', sans-serif; ;
  color: var(--main-wave);
  text-shadow: var(--text-shadow-extra);
  cursor: default;
}

.OpponentTurnPause-secondLayer-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 60px;
  position: absolute;
  bottom: 6px;
}
/* ///// 2nd Layer /////////// */