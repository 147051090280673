.UserProfile-err {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;
  background: var(--forth-nav);
  border-radius: .2rem;
  box-shadow: var(--box-shadow-signin);
  cursor: default;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.UserProfile-err-text {
  font: bold 1.068rem 'Raleway', sans-serif;;
  color: var(--main-wave);
  text-shadow: var(--text-shadow);
}